import axios from 'axios';

const API_URL = 'http://election-backend:8080/api/elections';

export const getAllConstituencies = () => {
    return axios.get(`${API_URL}/constituencies-all`);
};

export const getConstituencyById = (id) => {
    return axios.get(`${API_URL}/constituencies-all/${id}`);
};

export const createConstituency = (data) => {
    return axios.post(`${API_URL}/constituencies-all`, data);
};

export const updateConstituency = (id, data) => {
    return axios.put(`${API_URL}/constituencies-all/${id}`, data);
};

export const deleteConstituency = (id) => {
    return axios.delete(`${API_URL}/constituencies-all/${id}`);
};

// Mandal API calls
export const getAllMandals = () => {
    return axios.get(`${API_URL}/mandals-v1`);
};

export const getMandalById = (id) => {
    return axios.get(`${API_URL}/mandals-v1/${id}`);
};
export const getMandalsByConstituency = (id) => {
    return axios.get(`${API_URL}/constituency-v1/${id}`);
};



// Ward API calls
export const getAllWards = () => axios.get(`${API_URL}/wards`);
export const getWardById = (id) => axios.get(`${API_URL}/wards/${id}`);
export const createWard = (data) => axios.post(`${API_URL}/wards`, data);
export const updateWard = (id, data) => axios.put(`${API_URL}/wards/${id}`, data);
export const deleteWard = (id) => axios.delete(`${API_URL}/wards/${id}`);


export const createMandal = (data) => {
    return axios.post(`${API_URL}/mandals-v1`, data);
};

export const updateMandal = (id, data) => {
    return axios.put(`${API_URL}/mandals-v1/${id}`, data);
};

export const deleteMandal = (id) => {
    return axios.delete(`${API_URL}/mandals-v1/${id}`);
};
// GramPanchayat API calls
export const getAllGramPanchayats = () => {
    return axios.get(`${API_URL}/gram-panchayats`);
};

export const getGramPanchayatById = (id) => {
    return axios.get(`${API_URL}/gram-panchayats/${id}`);
};

export const createGramPanchayat = (data) => {
    return axios.post(`${API_URL}/gram-panchayats`, data);
};

export const updateGramPanchayat = (id, data) => {
    return axios.put(`${API_URL}/gram-panchayats/${id}`, data);
};

export const deleteGramPanchayat = (id) => {
    return axios.delete(`${API_URL}/gram-panchayats/${id}`);
};


// PollingStation API calls
export const getAllPollingStations = () => axios.get(`${API_URL}/polling-stations`);
export const getPollingStationById = (id) => axios.get(`${API_URL}/polling-stations/${id}`);
export const createPollingStation = (data) => axios.post(`${API_URL}/polling-stations`, data);
export const updatePollingStation = (id, data) => axios.put(`${API_URL}/polling-stations/${id}`, data);
export const deletePollingStation = (id) => axios.delete(`${API_URL}/polling-stations/${id}`);
