import {
    getAllPollingStations,
    getPollingStationById,
    createPollingStation,
    updatePollingStation,
    deletePollingStation
} from '@/api';

const state = {
    pollingStations: [],
    pollingStation: {}
};

const getters = {
    allPollingStations: (state) => state.pollingStations,
    singlePollingStation: (state) => state.pollingStation
};

const actions = {
    async fetchPollingStations({ commit }) {
        const response = await getAllPollingStations();
        commit('setPollingStations', response.data);
    },
    async fetchPollingStation({ commit }, id) {
        const response = await getPollingStationById(id);
        commit('setPollingStation', response.data);
    },
    async createPollingStation({ commit }, data) {
        const response = await createPollingStation(data);
        commit('newPollingStation', response.data);
    },
    async updatePollingStation({ commit }, { id, data }) {
        const response = await updatePollingStation(id, data);
        commit('updatePollingStation', response.data);
    },
    async deletePollingStation({ commit }, id) {
        await deletePollingStation(id);
        commit('removePollingStation', id);
    }
};

const mutations = {
    setPollingStations: (state, pollingStations) => (state.pollingStations = pollingStations),
    setPollingStation: (state, pollingStation) => (state.pollingStation = pollingStation),
    newPollingStation: (state, pollingStation) => state.pollingStations.push(pollingStation),
    updatePollingStation: (state, updatedPollingStation) => {
        const index = state.pollingStations.findIndex(ps => ps.id === updatedPollingStation.id);
        if (index !== -1) {
            state.pollingStations.splice(index, 1, updatedPollingStation);
        }
    },
    removePollingStation: (state, id) => (state.pollingStations = state.pollingStations.filter(ps => ps.id !== id))
};

export default {
    state,
    getters,
    actions,
    mutations
};
