<template>
  <div>
    <h1>{{ isEditMode ? 'Edit' : 'Add New' }} Polling Station</h1>
    <form @submit.prevent="submitForm">
      <div>
        <label>Name</label>
        <input type="text" v-model="pollingStation.name" required />
      </div>
      <div>
        <label>Description</label>
        <input type="text" v-model="pollingStation.description" required />
      </div>
      <div>
        <label>Total Voters</label>
        <input type="number" v-model="pollingStation.totalVoters" required />
      </div>
      <div>
        <label>EVM Count</label>
        <input type="number" v-model="pollingStation.evmCount" required />
      </div>
      <div>
        <label>Ward</label>
        <select v-model="pollingStation.wardId" required>
          <option v-for="ward in wards" :key="ward.id" :value="ward.id">
            {{ ward.name }}
          </option>
        </select>
      </div>
      <button type="submit">{{ isEditMode ? 'Update' : 'Create' }}</button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PollingStationForm',
  data() {
    return {
      pollingStation: {
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        wardId: ''
      },
      isEditMode: false
    };
  },
  computed: {
    ...mapGetters(['allWards'])
  },
  created() {
    this.fetchWards();
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.fetchPollingStation(this.$route.params.id).then(response => {
        this.pollingStation = response.data;
      });
    }
  },
  methods: {
    ...mapActions(['fetchPollingStation', 'createPollingStation', 'updatePollingStation', 'fetchWards']),
    submitForm() {
      if (this.isEditMode) {
        this.updatePollingStation({
          id: this.$route.params.id,
          data: this.pollingStation
        }).then(() => {
          this.$router.push('/polling-stations');
        });
      } else {
        this.createPollingStation(this.pollingStation).then(() => {
          this.$router.push('/polling-stations');
        });
      }
    }
  }
};
</script>
