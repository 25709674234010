<template>
  <div class="container mt-4">
    <h1 class="mb-4">Gram Panchayats</h1>
    <router-link to="/gram-panchayats/new" class="btn btn-primary mb-3">Add New Gram Panchayat</router-link>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">Total Voters</th>
        <th scope="col">EVM Count</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(gramPanchayat, index) in allGramPanchayats" :key="gramPanchayat.gramPanchayatId">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ gramPanchayat.name }}</td>
        <td>{{ gramPanchayat.description }}</td>
        <td>{{ gramPanchayat.totalVoters }}</td>
        <td>{{ gramPanchayat.evmCount }}</td>
        <td>
          <router-link :to="{ name: 'GramPanchayatEdit', params: { id: gramPanchayat.gramPanchayatId } }" class="btn btn-sm btn-warning me-2">Edit</router-link>
          <button @click="deleteGramPanchayat(gramPanchayat.gramPanchayatId)" class="btn btn-sm btn-danger">Delete</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'GramPanchayatList',
  computed: {
    ...mapGetters(['allGramPanchayats'])
  },
  created() {
    this.fetchGramPanchayats();
  },
  methods: {
    ...mapActions(['fetchGramPanchayats', 'deleteGramPanchayat'])
  }
};
</script>
