<template>
  <div>
    <h1>Results for Constituency: {{ $route.params.name }}</h1>
    <!-- Add content here to display constituency-specific results -->
  </div>
</template>

<script>
export default {
  props: ['name']
};
</script>

<style scoped>
/* Add any necessary styling */
</style>
