<template>
  <div>
    <ConstituencyForm />
  </div>
</template>

<script>
import ConstituencyForm from '@/components/ConstituencyForm';

export default {
  name: 'ConstituencyEditView',
  components: {
    ConstituencyForm
  }
};
</script>
