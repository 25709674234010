<template>
  <div>
    <GramPanchayatList />
  </div>
</template>

<script>
import GramPanchayatList from '@/components/GramPanchayatList';

export default {
  name: 'GramPanchayatListView',
  components: {
    GramPanchayatList
  }
};
</script>
