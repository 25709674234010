<template>
  <div>
    <GramPanchayatForm />
  </div>
</template>

<script>
import GramPanchayatForm from '@/components/GramPanchayatForm';

export default {
  name: 'GramPanchayatEditView',
  components: {
    GramPanchayatForm
  }
};
</script>
