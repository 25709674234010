import {
    getAllWards,
    getWardById,
    createWard,
    updateWard,
    deleteWard
} from '@/api';

const state = {
    wards: [],
    ward: {}
};

const getters = {
    allWards: (state) => state.wards,
    singleWard: (state) => state.ward
};

const actions = {
    async fetchWards({ commit }) {
        const response = await getAllWards();
        commit('setWards', response.data);
    },
    async fetchWard({ commit }, id) {
        const response = await getWardById(id);
        commit('setWard', response.data);
    },
    async createWard({ commit }, data) {
        const response = await createWard(data);
        commit('newWard', response.data);
    },
    async updateWard({ commit }, { id, data }) {
        const response = await updateWard(id, data);
        commit('updateWard', response.data);
    },
    async deleteWard({ commit }, id) {
        await deleteWard(id);
        commit('removeWard', id);
    }
};

const mutations = {
    setWards: (state, wards) => (state.wards = wards),
    setWard: (state, ward) => (state.ward = ward),
    newWard: (state, ward) => state.wards.push(ward),
    updateWard: (state, updatedWard) => {
        const index = state.wards.findIndex(w => w.id === updatedWard.id);
        if (index !== -1) {
            state.wards.splice(index, 1, updatedWard);
        }
    },
    removeWard: (state, id) => (state.wards = state.wards.filter(w => w.id !== id))
};

export default {
    state,
    getters,
    actions,
    mutations
};
