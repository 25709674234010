<template>
  <div>
    <h1>Constituencies</h1>
    <router-link to="/constituencies/new">Add New Constituency</router-link>
    <ul>
      <li v-for="constituency in allConstituencies" :key="constituency.id">
        {{ constituency.name }}
        <router-link :to="{ name: 'EditConstituency', params: { id: constituency.id } }">Edit</router-link>
        <button @click="deleteConstituency(constituency.id)">Delete</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ListConstituency',
  computed: {
    ...mapGetters(['allConstituencies'])
  },
  created() {
    this.fetchConstituencies();
  },
  methods: {
    ...mapActions(['fetchConstituencies', 'deleteConstituency'])
  }
};
</script>
