<!-- src/components/MandalForm.vue -->
<template>
  <div class="container mt-4">
    <h1 class="mb-4">{{ isEditMode ? 'Edit' : 'Add New' }} Mandal</h1>
    <form @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" id="name" v-model="mandal.name" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">Description</label>
        <input type="text" id="description" v-model="mandal.description" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="totalVoters" class="form-label">Total Voters</label>
        <input type="number" id="totalVoters" v-model="mandal.totalVoters" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="evmCount" class="form-label">EVM Count</label>
        <input type="number" id="evmCount" v-model="mandal.evmCount" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="constituency" class="form-label">Constituency</label>
        <select id="constituency" v-model="mandal.constituencyId" class="form-select" required>
          <option v-for="constituency in allConstituencies" :key="constituency.id" :value="constituency.id">
            {{ constituency.name }}
          </option>
        </select>
      </div>

      <!-- Demographics Section -->
      <h3 class="mt-4">Demographics</h3>
      <div class="mb-3">
        <label for="male" class="form-label">Male</label>
        <input type="number" id="male" v-model="mandal.demographics.male" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="female" class="form-label">Female</label>
        <input type="number" id="female" v-model="mandal.demographics.female" class="form-control" required />
      </div>
      <div class="mb-3">
        <label for="others" class="form-label">Others</label>
        <input type="number" id="others" v-model="mandal.demographics.others" class="form-control" required />
      </div>

      <!-- Candidates Section -->
      <h3 class="mt-4">Candidates</h3>
      <div v-for="(candidate, index) in mandal.candidates" :key="index" class="border p-3 mb-3">
        <div class="mb-3">
          <label :for="'candidate-name-' + index" class="form-label">Name</label>
          <input type="text" :id="'candidate-name-' + index" v-model="candidate.name" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'candidate-party-' + index" class="form-label">Party</label>
          <input type="text" :id="'candidate-party-' + index" v-model="candidate.party" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'candidate-votes-' + index" class="form-label">Votes</label>
          <input type="number" :id="'candidate-votes-' + index" v-model="candidate.votes" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'candidate-postalVotes-' + index" class="form-label">Postal Votes</label>
          <input type="number" :id="'candidate-postalVotes-' + index" v-model="candidate.postalVotes" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'candidate-totalVotes-' + index" class="form-label">Total Votes</label>
          <input type="number" :id="'candidate-totalVotes-' + index" v-model="candidate.totalVotes" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'candidate-percentage-' + index" class="form-label">Percentage</label>
          <input type="number" :id="'candidate-percentage-' + index" v-model="candidate.percentage" class="form-control" required />
        </div>
        <div class="form-check mb-3">
          <input type="checkbox" :id="'candidate-winner-' + index" v-model="candidate.winner" class="form-check-input" />
          <label :for="'candidate-winner-' + index" class="form-check-label">Winner</label>
        </div>
        <button type="button" class="btn btn-danger" @click="removeCandidate(index)">Remove Candidate</button>
      </div>
      <button type="button" class="btn btn-secondary mb-3" @click="addCandidate">Add Candidate</button>

      <!-- Gram Panchayats Section -->
      <h3 class="mt-4">Gram Panchayats</h3>
      <div v-for="(gramPanchayat, gIndex) in mandal.gramPanchayats" :key="gIndex" class="border p-3 mb-3">
        <div class="mb-3">
          <label :for="'gramPanchayat-name-' + gIndex" class="form-label">Name</label>
          <input type="text" :id="'gramPanchayat-name-' + gIndex" v-model="gramPanchayat.name" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'gramPanchayat-description-' + gIndex" class="form-label">Description</label>
          <input type="text" :id="'gramPanchayat-description-' + gIndex" v-model="gramPanchayat.description" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'gramPanchayat-totalVoters-' + gIndex" class="form-label">Total Voters</label>
          <input type="number" :id="'gramPanchayat-totalVoters-' + gIndex" v-model="gramPanchayat.totalVoters" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'gramPanchayat-evmCount-' + gIndex" class="form-label">EVM Count</label>
          <input type="number" :id="'gramPanchayat-evmCount-' + gIndex" v-model="gramPanchayat.evmCount" class="form-control" required />
        </div>

        <!-- Gram Panchayat Demographics -->
        <h4>Demographics</h4>
        <div class="mb-3">
          <label :for="'gramPanchayat-male-' + gIndex" class="form-label">Male</label>
          <input type="number" :id="'gramPanchayat-male-' + gIndex" v-model="gramPanchayat.demographics.male" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'gramPanchayat-female-' + gIndex" class="form-label">Female</label>
          <input type="number" :id="'gramPanchayat-female-' + gIndex" v-model="gramPanchayat.demographics.female" class="form-control" required />
        </div>
        <div class="mb-3">
          <label :for="'gramPanchayat-others-' + gIndex" class="form-label">Others</label>
          <input type="number" :id="'gramPanchayat-others-' + gIndex" v-model="gramPanchayat.demographics.others" class="form-control" required />
        </div>

        <!-- Gram Panchayat Candidates -->
        <h4>Candidates</h4>
        <div v-for="(candidate, cIndex) in gramPanchayat.candidates" :key="cIndex" class="border p-3 mb-3">
          <div class="mb-3">
            <label :for="'gramPanchayat-candidate-name-' + gIndex + '-' + cIndex" class="form-label">Name</label>
            <input type="text" :id="'gramPanchayat-candidate-name-' + gIndex + '-' + cIndex" v-model="candidate.name" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'gramPanchayat-candidate-party-' + gIndex + '-' + cIndex" class="form-label">Party</label>
            <input type="text" :id="'gramPanchayat-candidate-party-' + gIndex + '-' + cIndex" v-model="candidate.party" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'gramPanchayat-candidate-votes-' + gIndex + '-' + cIndex" class="form-label">Votes</label>
            <input type="number" :id="'gramPanchayat-candidate-votes-' + gIndex + '-' + cIndex" v-model="candidate.votes" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'gramPanchayat-candidate-postalVotes-' + gIndex + '-' + cIndex" class="form-label">Postal Votes</label>
            <input type="number" :id="'gramPanchayat-candidate-postalVotes-' + gIndex + '-' + cIndex" v-model="candidate.postalVotes" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'gramPanchayat-candidate-totalVotes-' + gIndex + '-' + cIndex" class="form-label">Total Votes</label>
            <input type="number" :id="'gramPanchayat-candidate-totalVotes-' + gIndex + '-' + cIndex" v-model="candidate.totalVotes" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'gramPanchayat-candidate-percentage-' + gIndex + '-' + cIndex" class="form-label">Percentage</label>
            <input type="number" :id="'gramPanchayat-candidate-percentage-' + gIndex + '-' + cIndex" v-model="candidate.percentage" class="form-control" required />
          </div>
          <div class="form-check mb-3">
            <input type="checkbox" :id="'gramPanchayat-candidate-winner-' + gIndex + '-' + cIndex" v-model="candidate.winner" class="form-check-input" />
            <label :for="'gramPanchayat-candidate-winner-' + gIndex + '-' + cIndex" class="form-check-label">Winner</label>
          </div>
          <button type="button" class="btn btn-danger" @click="removeGramPanchayatCandidate(gIndex, cIndex)">Remove Candidate</button>
        </div>
        <button type="button" class="btn btn-secondary mb-3" @click="addGramPanchayatCandidate(gIndex)">Add Candidate</button>

        <!-- Gram Panchayat Wards -->
        <h4>Wards</h4>
        <div v-for="(ward, wIndex) in gramPanchayat.wards" :key="wIndex" class="border p-3 mb-3">
          <div class="mb-3">
            <label :for="'ward-name-' + gIndex + '-' + wIndex" class="form-label">Name</label>
            <input type="text" :id="'ward-name-' + gIndex + '-' + wIndex" v-model="ward.name" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'ward-description-' + gIndex + '-' + wIndex" class="form-label">Description</label>
            <input type="text" :id="'ward-description-' + gIndex + '-' + wIndex" v-model="ward.description" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'ward-totalVoters-' + gIndex + '-' + wIndex" class="form-label">Total Voters</label>
            <input type="number" :id="'ward-totalVoters-' + gIndex + '-' + wIndex" v-model="ward.totalVoters" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'ward-evmCount-' + gIndex + '-' + wIndex" class="form-label">EVM Count</label>
            <input type="number" :id="'ward-evmCount-' + gIndex + '-' + wIndex" v-model="ward.evmCount" class="form-control" required />
          </div>

          <!-- Ward Demographics -->
          <h5>Demographics</h5>
          <div class="mb-3">
            <label :for="'ward-male-' + gIndex + '-' + wIndex" class="form-label">Male</label>
            <input type="number" :id="'ward-male-' + gIndex + '-' + wIndex" v-model="ward.demographics.male" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'ward-female-' + gIndex + '-' + wIndex" class="form-label">Female</label>
            <input type="number" :id="'ward-female-' + gIndex + '-' + wIndex" v-model="ward.demographics.female" class="form-control" required />
          </div>
          <div class="mb-3">
            <label :for="'ward-others-' + gIndex + '-' + wIndex" class="form-label">Others</label>
            <input type="number" :id="'ward-others-' + gIndex + '-' + wIndex" v-model="ward.demographics.others" class="form-control" required />
          </div>

          <!-- Ward Candidates -->
          <h5>Candidates</h5>
          <div v-for="(candidate, wcIndex) in ward.candidates" :key="wcIndex" class="border p-3 mb-3">
            <div class="mb-3">
              <label :for="'ward-candidate-name-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-label">Name</label>
              <input type="text" :id="'ward-candidate-name-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.name" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-candidate-party-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-label">Party</label>
              <input type="text" :id="'ward-candidate-party-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.party" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-candidate-votes-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-label">Votes</label>
              <input type="number" :id="'ward-candidate-votes-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.votes" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-candidate-postalVotes-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-label">Postal Votes</label>
              <input type="number" :id="'ward-candidate-postalVotes-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.postalVotes" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-candidate-totalVotes-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-label">Total Votes</label>
              <input type="number" :id="'ward-candidate-totalVotes-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.totalVotes" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-candidate-percentage-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-label">Percentage</label>
              <input type="number" :id="'ward-candidate-percentage-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.percentage" class="form-control" required />
            </div>
            <div class="form-check mb-3">
              <input type="checkbox" :id="'ward-candidate-winner-' + gIndex + '-' + wIndex + '-' + wcIndex" v-model="candidate.winner" class="form-check-input" />
              <label :for="'ward-candidate-winner-' + gIndex + '-' + wIndex + '-' + wcIndex" class="form-check-label">Winner</label>
            </div>
            <button type="button" class="btn btn-danger" @click="removeWardCandidate(gIndex, wIndex, wcIndex)">Remove Candidate</button>
          </div>
          <button type="button" class="btn btn-secondary mb-3" @click="addWardCandidate(gIndex, wIndex)">Add Candidate</button>

          <!-- Ward Polling Stations -->
          <h5>Polling Stations</h5>
          <div v-for="(pollingStation, pIndex) in ward.pollingStations" :key="pIndex" class="border p-3 mb-3">
            <div class="mb-3">
              <label :for="'ward-pollingStation-name-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">Name</label>
              <input type="text" :id="'ward-pollingStation-name-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.name" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-pollingStation-description-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">Description</label>
              <input type="text" :id="'ward-pollingStation-description-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.description" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-pollingStation-totalVoters-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">Total Voters</label>
              <input type="number" :id="'ward-pollingStation-totalVoters-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.totalVoters" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-pollingStation-evmCount-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">EVM Count</label>
              <input type="number" :id="'ward-pollingStation-evmCount-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.evmCount" class="form-control" required />
            </div>

            <!-- Polling Station Demographics -->
            <h6>Demographics</h6>
            <div class="mb-3">
              <label :for="'ward-pollingStation-male-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">Male</label>
              <input type="number" :id="'ward-pollingStation-male-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.demographics.male" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-pollingStation-female-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">Female</label>
              <input type="number" :id="'ward-pollingStation-female-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.demographics.female" class="form-control" required />
            </div>
            <div class="mb-3">
              <label :for="'ward-pollingStation-others-' + gIndex + '-' + wIndex + '-' + pIndex" class="form-label">Others</label>
              <input type="number" :id="'ward-pollingStation-others-' + gIndex + '-' + wIndex + '-' + pIndex" v-model="pollingStation.demographics.others" class="form-control" required />
            </div>

            <!-- Polling Station Candidates -->
            <h6>Candidates</h6>
            <div v-for="(candidate, pcIndex) in pollingStation.candidates" :key="pcIndex" class="border p-3 mb-3">
              <div class="mb-3">
                <label :for="'ward-pollingStation-candidate-name-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-label">Name</label>
                <input type="text" :id="'ward-pollingStation-candidate-name-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.name" class="form-control" required />
              </div>
              <div class="mb-3">
                <label :for="'ward-pollingStation-candidate-party-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-label">Party</label>
                <input type="text" :id="'ward-pollingStation-candidate-party-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.party" class="form-control" required />
              </div>
              <div class="mb-3">
                <label :for="'ward-pollingStation-candidate-votes-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-label">Votes</label>
                <input type="number" :id="'ward-pollingStation-candidate-votes-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.votes" class="form-control" required />
              </div>
              <div class="mb-3">
                <label :for="'ward-pollingStation-candidate-postalVotes-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-label">Postal Votes</label>
                <input type="number" :id="'ward-pollingStation-candidate-postalVotes-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.postalVotes" class="form-control" required />
              </div>
              <div class="mb-3">
                <label :for="'ward-pollingStation-candidate-totalVotes-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-label">Total Votes</label>
                <input type="number" :id="'ward-pollingStation-candidate-totalVotes-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.totalVotes" class="form-control" required />
              </div>
              <div class="mb-3">
                <label :for="'ward-pollingStation-candidate-percentage-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-label">Percentage</label>
                <input type="number" :id="'ward-pollingStation-candidate-percentage-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.percentage" class="form-control" required />
              </div>
              <div class="form-check mb-3">
                <input type="checkbox" :id="'ward-pollingStation-candidate-winner-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" v-model="candidate.winner" class="form-check-input" />
                <label :for="'ward-pollingStation-candidate-winner-' + gIndex + '-' + wIndex + '-' + pIndex + '-' + pcIndex" class="form-check-label">Winner</label>
              </div>
              <button type="button" class="btn btn-danger" @click="removePollingStationCandidate(gIndex, wIndex, pIndex, pcIndex)">Remove Candidate</button>
            </div>
            <button type="button" class="btn btn-secondary mb-3" @click="addPollingStationCandidate(gIndex, wIndex, pIndex)">Add Candidate</button>
          </div>
          <button type="button" class="btn btn-danger" @click="removePollingStation(gIndex, wIndex, pIndex)">Remove Polling Station</button>
        </div>
        <button type="button" class="btn btn-secondary mb-3" @click="addPollingStation(gIndex, wIndex)">Add Polling Station</button>
        <button type="button" class="btn btn-danger" @click="removeWard(gIndex, wIndex)">Remove Ward</button>

        <button type="button" class="btn btn-secondary mb-3" @click="addWard(gIndex)">Add Ward</button>
        <button type="button" class="btn btn-danger" @click="removeGramPanchayat(gIndex)">Remove Gram Panchayat</button>
      </div>
      <div class="col-12">
        <button type="button" class="btn btn-secondary mb-3" @click="addGramPanchayat">Add Gram Panchayat</button>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary">{{ isEditMode ? 'Update' : 'Create' }}</button>
        <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MandalForm',
  data() {
    return {
      mandal: {
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        constituencyId: '',
        demographics: {
          demographicId: '',
          male: 0,
          female: 0,
          others: 0
        },
        candidates: [],
        gramPanchayats: []
      },
      isEditMode: false
    };
  },
  computed: {
    ...mapGetters(['allConstituencies'])
  },
  created() {
    this.fetchConstituencies();
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.fetchMandal(this.$route.params.id).then(response => {
        this.mandal = response.data;
      });
    }
  },
  methods: {
    ...mapActions(['fetchMandal', 'createMandal', 'updateMandal', 'fetchConstituencies']),
    submitForm() {
      if (this.isEditMode) {
        this.updateMandal({
          id: this.$route.params.id,
          data: this.mandal
        }).then(() => {
          this.$router.push('/mandals-v1');
        });
      } else {
        this.createMandal(this.mandal).then(() => {
          this.$router.push('/mandals-v1');
        });
      }
    },
    addCandidate() {
      this.mandal.candidates.push({
        candidateId: '',
        name: '',
        party: '',
        votes: 0,
        postalVotes: 0,
        totalVotes: 0,
        percentage: 0,
        winner: false
      });

    },
    cancel() {
      this.$router.push('/mandals-v1');
    },
    removeCandidate(index) {
      this.mandal.candidates.splice(index, 1);
    },
    addGramPanchayat() {
      this.mandal.gramPanchayats.push({
        gramPanchayatId: '',
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        demographics: {
          demographicId: '',
          male: 0,
          female: 0,
          others: 0
        },
        candidates: [],
        wards: []
      });
    },
    removeGramPanchayat(index) {
      this.mandal.gramPanchayats.splice(index, 1);
    },
    addGramPanchayatCandidate(gramPanchayatIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].candidates.push({
        candidateId: '',
        name: '',
        party: '',
        votes: 0,
        postalVotes: 0,
        totalVotes: 0,
        percentage: 0,
        winner: false
      });
    },
    removeGramPanchayatCandidate(gramPanchayatIndex, candidateIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].candidates.splice(candidateIndex, 1);
    },
    addWard(gramPanchayatIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards.push({
        wardId: '',
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        demographics: {
          demographicId: '',
          male: 0,
          female: 0,
          others: 0
        },
        candidates: [],
        pollingStations: []
      });
    },
    removeWard(gramPanchayatIndex, wardIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards.splice(wardIndex, 1);
    },
    addWardCandidate(gramPanchayatIndex, wardIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards[wardIndex].candidates.push({
        candidateId: '',
        name: '',
        party: '',
        votes: 0,
        postalVotes: 0,
        totalVotes: 0,
        percentage: 0,
        winner: false
      });
    },
    removeWardCandidate(gramPanchayatIndex, wardIndex, candidateIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards[wardIndex].candidates.splice(candidateIndex, 1);
    },
    addPollingStation(gramPanchayatIndex, wardIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards[wardIndex].pollingStations.push({
        pollingStationId: '',
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        demographics: {
          demographicId: '',
          male: 0,
          female: 0,
          others: 0
        },
        candidates: []
      });
    },
    removePollingStation(gramPanchayatIndex, wardIndex, pollingStationIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards[wardIndex].pollingStations.splice(pollingStationIndex, 1);
    },
    addPollingStationCandidate(gramPanchayatIndex, wardIndex, pollingStationIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards[wardIndex].pollingStations[pollingStationIndex].candidates.push({
        candidateId: '',
        name: '',
        party: '',
        votes: 0,
        postalVotes: 0,
        totalVotes: 0,
        percentage: 0,
        winner: false
      });
    },
    removePollingStationCandidate(gramPanchayatIndex, wardIndex, pollingStationIndex, candidateIndex) {
      this.mandal.gramPanchayats[gramPanchayatIndex].wards[wardIndex].pollingStations[pollingStationIndex].candidates.splice(candidateIndex, 1);
    }
  }
};
</script>
