<template>
  <div>
    <MandalForm />
  </div>
</template>

<script>
import MandalForm from '@/components/MandalForm';

export default {
  name: 'MandalEditView',
  components: {
    MandalForm
  }
};
</script>
