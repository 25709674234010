import {
    getAllMandals,
    getMandalById,
    createMandal,
    updateMandal,
    deleteMandal,
    getMandalsByConstituency,
    getAllConstituencies
} from '@/api';

const state = {
    mandals: [],
    mandal: {},
    constituencies: [] // Add constituencies to the state
};

const getters = {
    allMandals: (state) => state.mandals,
    singleMandal: (state) => state.mandal,
    allConstituencies: (state) => state.constituencies // Add a getter for constituencies
};

const actions = {
    async fetchMandals({ commit }) {
        const response = await getAllMandals();
        commit('setMandals', response.data);
    },
    async fetchMandalsByConstituency({ commit }, constituencyId) {
        const response = await getMandalsByConstituency(constituencyId);
        commit('setMandals', response.data);
    },
    async fetchMandal({ commit }, id) {
        const response = await getMandalById(id);
        commit('setMandal', response.data);
    },
    async createMandal({ commit }, data) {
        const response = await createMandal(data);
        commit('newMandal', response.data);
    },
    async updateMandal({ commit }, { id, data }) {
        const response = await updateMandal(id, data);
        commit('updateMandal', response.data);
    },
    async deleteMandal({ commit }, id) {
        await deleteMandal(id);
        commit('removeMandal', id);
    },
    async fetchConstituencies({ commit }) { // Add action to fetch constituencies
        const response = await getAllConstituencies();
        commit('setConstituencies', response.data);
    }
};

const mutations = {
    setMandals: (state, mandals) => (state.mandals = mandals),
    setMandal: (state, mandal) => (state.mandal = mandal),
    newMandal: (state, mandal) => state.mandals.push(mandal),
    updateMandal: (state, updatedMandal) => {
        const index = state.mandals.findIndex(m => m.id === updatedMandal.id);
        if (index !== -1) {
            state.mandals.splice(index, 1, updatedMandal);
        }
    },
    removeMandal: (state, id) => (state.mandals = state.mandals.filter(m => m.id !== id)),
    setConstituencies: (state, constituencies) => (state.constituencies = constituencies) // Add mutation for constituencies
};

export default {
    state,
    getters,
    actions,
    mutations
};
