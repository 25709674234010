<template>
  <div>
    <PollingStationForm />
  </div>
</template>

<script>
import PollingStationForm from '@/components/PollingStationForm';

export default {
  name: 'PollingStationNewView',
  components: {
    PollingStationForm
  }
};
</script>
