<template>
  <div class="container mt-4">
    <WardForm />
  </div>
</template>

<script>
import WardForm from '@/components/WardForm';

export default {
  name: 'WardEditView',
  components: {
    WardForm
  }
};
</script>
