<template>
  <div class="container mt-4">
    <h1 class="mb-4">Mandals</h1>
    <router-link to="/mandals-v1/new" class="btn btn-primary mb-3">Add New Mandal</router-link>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">Total Votes</th>
        <th scope="col">EVM Count</th>
        <th scope="col">Constituency ID</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="mandal in allMandals" :key="mandal.mandalId">
        <td>{{ mandal.name }}</td>
        <td>{{ mandal.description }}</td>
        <td>{{ mandal.totalVoters }}</td>
        <td>{{ mandal.evmCount }}</td>
        <td>{{ mandal.constituencyId }}</td>
        <td>
          <router-link :to="{ name: 'EditMandal', params: { id: mandal.mandalId } }" class="btn btn-secondary btn-sm me-2">Edit</router-link>
          <button @click="deleteMandal(mandal.mandalId)" class="btn btn-danger btn-sm">Delete</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'MandalList',
  computed: {
    ...mapGetters(['allMandals'])
  },
  created() {
    this.fetchMandals();
  },
  methods: {
    ...mapActions(['fetchMandals', 'deleteMandal'])
  }
};
</script>
