<template>
  <div>
    <MandalList />
  </div>
</template>

<script>
import MandalList from '@/components/MandalList';

export default {
  name: 'MandalListView',
  components: {
    MandalList
  }
};
</script>
