<template>
  <div>
    <h1>Polling Stations</h1>
    <router-link to="/polling-stations/new">Add New Polling Station</router-link>
    <ul>
      <li v-for="pollingStation in allPollingStations" :key="pollingStation.pollingStationId">
        {{ pollingStation.name }}
        <router-link :to="{ name: 'EditPollingStation', params: { id: pollingStation.pollingStationId } }">Edit</router-link>
        <button @click="deletePollingStation(pollingStation.pollingStationId)">Delete</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PollingStationList',
  computed: {
    ...mapGetters(['allPollingStations'])
  },
  created() {
    this.fetchPollingStations();
  },
  methods: {
    ...mapActions(['fetchPollingStations', 'deletePollingStation'])
  }
};
</script>
