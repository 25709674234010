import { createRouter, createWebHashHistory } from 'vue-router';
import Constituencies from '@/components/Constituencies.vue';
import ConstituencyDetails from '@/components/ConstituencyDetails.vue';
import ElectionResults from '@/components/ElectionResults.vue';
import RegionDetails from '@/components/RegionDetails.vue';
import Regions from "@/components/Regions.vue";
import ElectionResultsList from "@/components/ElectionResultsList.vue";
import GramPanchayatListView from '@/views/GramPanchayatList.vue';
import GramPanchayatNewView from '@/views/GramPanchayatNew.vue';
import GramPanchayatEditView from '@/views/GramPanchayatEdit.vue';
import  ConstituencyListView from '@/views/ConstituencyList.vue';
import  ConstituencyNewView from '@/views/ConstituencyNew.vue';
import  ConstituencyEditView from '@/views/ConstituencyEdit.vue';
import 'bootstrap/dist/css/bootstrap.css';
import WardListView from '@/views/WardList.vue';
import WardNewView from '@/views/WardNew.vue';
import WardEditView from '@/views/WardEdit.vue';
import PollingStationListView from '@/views/PollingStationList.vue';
import PollingStationNewView from '@/views/PollingStationNew.vue';
import PollingStationEditView from '@/views/PollingStationEdit.vue';
import MandalListView from '@/views/MandalList.vue';
import MandalNewView from '@/views/MandalNew.vue';
import MandalEditView from '@/views/MandalEdit.vue';
import TotalResultsView from "@/views/TotalResultsView.vue";
import CandidateResultsView from "@/views/CandidateResultsView.vue";
import CandidateView from '@/views/CandidateView.vue';
import ConstituencyView from '@/views/ConstituencyView.vue';
import PartyView from '@/views/PartyView.vue';



const routes = [
    { path: '/', redirect: '/constituencies' },
    { path: '/constituencies', name: 'Constituencies', component: Constituencies },
    { path: '/constituencies/:id', name: 'ConstituencyDetails', component: ConstituencyDetails, props: true },
    { path: '/election-results/:id', name: 'ElectionResults', component: ElectionResults, props: true },
    { path: '/election-results', name: 'ElectionResultsList', component: ElectionResultsList, props: true },
    { path: '/mandals', name: 'Regions', component: Regions, props: true },
    { path: '/mandals/:id', name: 'RegionDetails', component: RegionDetails, props: true },
    {
        path: '/constituencies-list',
        name: 'ListConstituency',
        component: ConstituencyListView,
        props: true
    },
    {
        path: '/constituencies-list/new',
        name: 'NewConstituency',
        component: ConstituencyNewView,
        props: true
    },
    {
        path: '/constituencies-list/edit/:id',
        name: 'EditConstituency',
        component: ConstituencyEditView,
        props: true
    },
    {
        path: '/mandals-v1',
        name: 'ListManadal',
        component: MandalListView,
        props: true
    },
    {
        path: '/mandals-v1/new',
        name: 'NewMandal',
        component: MandalNewView,
        props: true
    },
    {
        path: '/mandals-v1/edit/:id',
        name: 'EditMandal',
        component: MandalEditView,
        props: true
    },
    {
        path: '/gram-panchayats',
        name: 'GramPanchayatList',
        component: GramPanchayatListView,
        props: true
    },
    {
        path: '/gram-panchayats/new',
        name: 'NewGramPanchayat',
        component: GramPanchayatNewView,
        props: true
    },
    {
        path: '/gram-panchayats/edit/:id',
        name: 'EditGramPanchayat',
        component: GramPanchayatEditView,
        props: true
    },
    {
        path: '/wards',
        name: 'WardList',
        component: WardListView,
        props: true
    },
    {
        path: '/wards/new',
        name: 'NewWard',
        component: WardNewView,
        props: true
    },
    {
        path: '/wards/edit/:id',
        name: 'WardEdit',
        component: WardEditView,
        props: true
    },
    {
        path: '/polling-stations',
        name: 'PollingStationList',
        component: PollingStationListView,
        props: true
    },
    {
        path: '/polling-stations/new',
        name: 'NewPollingStation',
        component: PollingStationNewView,
        props: true
    },
    {
        path: '/polling-stations/edit/:id',
        name: 'EditPollingStation',
        component: PollingStationEditView,
        props: true
    },
    {
        path: '/total-results',
        name: 'TotalResultsView',
        component: TotalResultsView,
        props: true
    },
    {
        path: '/total-results',
        name: 'TotalResultsView',
        component: TotalResultsView,
        props: true
    },
    {
        path: '/round-results-by-round',
        name: 'CandidateResultsView',
        component: CandidateResultsView,
        props: true
    },
    { path: '/candidates/:constituencyId/candidate/:candidateName', component: CandidateView, props: true },
    { path: '/constituency/:name', component: ConstituencyView, props: true },
    { path: '/party/:name', component: PartyView, props: true },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
