<template>
  <section class="container mt-5">
    <h2 class="text-primary mb-4">Candidate {{ candidateName }} Details</h2>
    <p class="description">
      This page provides detailed election results for a specific candidate. You can view the total votes received by the candidate, including EVM votes, postal votes, and the total percentage of votes. Additionally, detailed results by round are displayed in the table below.
    </p>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/total-results">Results By Parties</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="`/constituencies/${constituencyId}`">{{ constituency.name }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ candidateName }}</li>
      </ol>
    </nav>
    <p><strong>Constituency:</strong> {{ constituency.description }}</p>
    <p><strong>Total EVM Votes:</strong> {{ totalEvmVotes }}</p>
    <p><strong>Total Postal Votes:</strong> {{ totalPostalVotes }}</p>
    <p><strong>Total Votes:</strong> {{ totalVotes }}</p>
    <p><strong>Total Percentage of Votes:</strong> {{ totalVotesPercentage }}</p>

    <h3 class="text-secondary mt-4">Results per Round</h3>
    <vue-good-table
        :columns="candidateFields"
        :rows="filteredCandidates"
        :search-options="{ enabled: true }"
        :sort-options="{ enabled: true }"
        :pagination-options="{ enabled: true, perPage: 50 }"
    >
      <template v-slot:table-row="props">
        <span :class="getCellClass(props.column.field, props.row[props.column.field])">
          <vue-good-table
              v-if="props.column.field === 'rounds'"
              :columns="roundFields"
              :rows="props.row.rounds"
              :search-options="{ enabled: false }"
              :sort-options="{ enabled: false }"
              :pagination-options="{ enabled: false }"
          >
            <template v-slot:table-row="roundProps">
              <span>{{ roundProps.formattedRow[roundProps.column.field] }}</span>
            </template>
          </vue-good-table>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </span>
      </template>
      <template v-slot:table-footer>
        <tr>
          <td>Total Votes</td>
          <td v-for="(total, index) in totalVotesPerRound" :key="index">{{ total }}</td>
          <td>{{ totalVotesAllRounds }}</td>
        </tr>
      </template>
    </vue-good-table>
    <footer class="footer mt-4">
      <p>&copy; 2024 Election Results. All rights reserved.</p>
      <p class="disclaimer">
        Disclaimer: The data is sourced from the Election Commission of India. We are just providing an analytical platform to view the detailed information.
      </p>
    </footer>
  </section>
</template>

<script>
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';


const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;


export default {
  components: {
    VueGoodTable
  },
  props: ['constituencyId', 'candidateName'],
  data() {
    return {
      evmCount: 0,
      totalEvmVotes: 0,
      totalPostalVotes: 0,
      totalVotes: 0,
      totalVotesPercentage: 0,
      search: '',
      sortBy: '',
      sortDesc: false,
      currentPage: 1,
      constituency: {
        name: '',
        description: '',
        candidates: [],
        demographics: {
          male: 0,
          female: 0,
          others: 0
        },
        mandals: []
      },
      resultsByRounds: [],
      highestEvmVotes: 0,
      highestPostalVotes: 0,
      highestTotalVotes: 0,
      highestPercentage: 0,
      highestRoundValues: {},
      candidateColumns: [
        {label: 'S.No', field: 'serialNumber', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          }},
        {label: 'Name', field: 'name', sortable: true},
        {label: 'Party', field: 'party', sortable: true},
        {label: 'EVM Votes', field: 'votes', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          }},
        {label: 'Postal Votes', field: 'postalVotes', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          }},
        {label: 'Total Votes', field: 'totalVotes', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          }},
        {label: 'Percentage', field: 'percentage', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          }},
        {label: 'Winner', field: 'winner', sortable: true}
      ],
      candidateFields: [
        {label: 'S.No', field: 'serialNumber', sortable: true},
        {label: 'Candidate', field: 'name', sortable: true},
        {label: 'Party', field: 'party', sortable: true},
        {label: 'Results by Rounds', field: 'rounds'},
        {label: 'Total Votes', field: 'totalVotes', sortable: true}
      ],
      roundFields: [
        {label: 'Round Number', field: 'roundNumber', sortable: true},
        {label: 'Votes', field: 'votes', sortable: true}
      ]
    };
  },
  computed: {
    dynamicTableFields() {
      const fields = [
        {label: 'S.No', field: 'serialNumber', sortable: true},
        {label: 'Candidate', field: 'name', sortable: true},
        {label: 'Party', field: 'party', sortable: true}
      ];
      this.transformedCandidates.roundsArray.forEach(round => {
        fields.push({label: `Round ${round}`, field: `round${round}`, sortable: true});
      });
      fields.push({label: 'Total Votes', field: 'totalVotes', sortable: true});
      return fields;
    },
    totalVotesAllRounds() {
      return this.transformedCandidates.transformedData.reduce((acc, candidate) => acc + candidate.totalVotes, 0);
    },
    transformedCandidates() {
      const candidates = {};
      const roundsSet = new Set();

      this.resultsByRounds.forEach(constituency => {
        constituency.rounds.forEach(round => {
          roundsSet.add(round.roundNumber);
          round.candidates.forEach(candidate => {
            if (!candidates[candidate.name]) {
              candidates[candidate.name] = {name: candidate.name, party: candidate.party, rounds: {}, totalVotes: 0};
            }
            if (!candidates[candidate.name].rounds[round.roundNumber]) {
              candidates[candidate.name].rounds[round.roundNumber] = 0;
            }
            candidates[candidate.name].rounds[round.roundNumber] += candidate.votes;
            candidates[candidate.name].totalVotes += candidate.votes;
          });
        });
      });

      const roundsArray = Array.from(roundsSet).sort((a, b) => a - b);
      const transformedData = Object.values(candidates).map((candidate, index) => {
        candidate.serialNumber = index + 1;
        roundsArray.forEach(roundNumber => {
          candidate[`round${roundNumber}`] = candidate.rounds[roundNumber] || 0;
        });
        return candidate;
      });

      return {transformedData, roundsArray};
    },
    transformedCandidates1() {
      const candidates = {};

      this.resultsByRounds.forEach(constituency => {
        constituency.rounds.forEach(round => {
          round.candidates.forEach(candidate => {
            if (!candidates[candidate.name]) {
              candidates[candidate.name] = {
                name: candidate.name,
                party: candidate.party,
                rounds: {},
                totalVotes: 0
              };
            }

            if (!candidates[candidate.name].rounds[round.roundNumber]) {
              candidates[candidate.name].rounds[round.roundNumber] = {
                roundNumber: round.roundNumber,
                votes: 0
              };
            }

            candidates[candidate.name].rounds[round.roundNumber].votes += candidate.votes;
            candidates[candidate.name].totalVotes += candidate.votes;
          });
        });
      });

      Object.values(candidates).forEach((candidate, index) => {
        candidate.serialNumber = index + 1;
        candidate.rounds = Object.values(candidate.rounds);
      });

      return Object.values(candidates);
    },
    filteredCandidates() {
      return this.candidateName
          ? this.transformedCandidates1.filter(candidate => candidate.name === this.candidateName)
          : this.transformedCandidates1;
    }
  },
  created() {
    this.fetchData();
    this.fetchRoundResults();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollToBottom() {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    },
    getCellClass(field, value) {
      if (field === 'votes' || field === 'postalVotes' || field === 'totalVotes' || field.startsWith('round')) {
        const maxValues = {
          votes: this.highestEvmVotes,
          postalVotes: this.highestPostalVotes,
          totalVotes: this.highestTotalVotes,
        };
        if (field.startsWith('round')) {
          const round = field.replace('round', '');
          return value === this.highestRoundValues[round] ? 'highlight-cell' : '';
        }
        return value === maxValues[field] ? 'highlight-cell' : '';
      }
      if (field === 'percentage') {
        return value.toFixed(2) === this.highestPercentage.toFixed(2) ? 'highlight-cell' : '';
      }
      return '';
    },
    async fetchData() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/constituencies/${this.constituencyId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.constituency = data;

        const candidate = data.candidates.find(c => c.name === this.candidateName);
        if (candidate) {
          this.evmCount = candidate.evmCount;
          this.totalEvmVotes = candidate.votes;
          this.totalPostalVotes = candidate.postalVotes;
          this.totalVotes = candidate.totalVotes;
          this.totalVotesPercentage = candidate.percentage;
        }
      } catch (error) {
        console.error('Error fetching constituency:', error);
      }
    },
    async fetchRoundResults() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/round-results/${this.constituencyId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.resultsByRounds = await response.json();
      } catch (error) {
        console.error('Error fetching round results:', error);
      }
    },
    updateSortBy(val) {
      this.sortBy = val;
    },
    updateSortDesc(val) {
      this.sortDesc = val;
    },
    updateSortByRounds(val) {
      this.sortByRounds = val;
    },
    updateSortDescRounds(val) {
      this.sortDescRounds = val;
    },
    updateSortByRegions(val) {
      this.sortByRegions = val;
    },
    updateSortDescRegions(val) {
      this.sortDescRegions = val;
    },
    calculateTotalVotes(candidate) {
      return candidate.rounds.reduce((total, round) => total + round.votes, 0);
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

section {
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

h2, h3 {
  color: #1f3c88;
}

.breadcrumb {
  background-color: #f8f9fa;
  padding: 10px 15px;
  margin-bottom: 20px;
  list-style: none;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 1.1em;
  margin: 10px 0;
}

.highlight-cell {
  background-color: #ffd7b5; /* Light orange color */
}

a {
  display: inline-block;
  color: #42b983;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, background-color 0.3s;
}

a:hover {
  color: #1f3c88;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px;
}

.scroll-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.description {
  font-size: 1.1em;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.1em;
  margin-bottom: 1rem;
}
.container {
  padding-top: 10px; /* Reduce top white space */
}
.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 20px;
}
.disclaimer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}

</style>
