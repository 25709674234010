<template>
  <section class="container mt-5">
    <h2 class="text-primary mb-4">{{ constituency.name }} Details</h2>
    <p class="description">
      This page provides detailed information about the selected constituency, including the total votes, vote
      percentages, and results per round. It also features a detailed table of candidates and their respective votes, as
      well as visual representations of vote percentages.
    </p>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/constituencies">Constituencies</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ constituency.name }}</li>
      </ol>
    </nav>
    <p class="lead">{{ constituency.description }}</p>
    <p><strong>EVM Count:</strong> {{ constituency.evmCount }}</p>
    <p><strong>Total EVM Votes:</strong> {{ totalEvmVotes }}</p>
    <p><strong>Total Postal Votes:</strong> {{ totalPostalVotes }}</p>
    <p><strong>Total Votes:</strong> {{ totalVotes }}</p>
    <p><strong>Total Percentage of Votes:</strong> {{ totalVotesPercentage }}</p>
    <section class="container mt-5">
      <div class="d-flex justify-content-end mb-4">
        <button class="btn btn-secondary me-2" @click="exportToPDF">Export to PDF</button>
      </div>
    </section>
    <h3 class="text-secondary mt-4">Candidates</h3>
    <div class="table-responsive">
      <vue-good-table
          :columns="candidateColumns"
          :pagination-options="{ enabled: true, perPage: 50 }"
          :rows="constituency.candidates"
          :search-options="{ enabled: true }"
          :sort-options="{ enabled: true }"
          class="mb-4"
      >
        <template v-slot:table-row="props">
          <span :class="getCellClass(props.column.field, props.row[props.column.field])">
            <span v-if="props.column.field === 'winner'">
              {{ props.row[props.column.field] ? 'Won' : 'Lost' }}
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </span>
        </template>
      </vue-good-table>
    </div>
    <h3 class="text-secondary mt-4">Vote Percentage by Candidate</h3>
    <div ref="chartContainer">
      <vote-percentage-chart :candidates="constituency.candidates"></vote-percentage-chart>
    </div>
    <h3 class="text-secondary mt-4">Results per Round (Table)</h3>
    <div class="table-responsive">
      <vue-good-table
          :columns="dynamicTableFields"
          :rows="transformedCandidates.transformedData"
          :search-options="{ enabled: true }"
          :sort-options="{ enabled: true }"
          :pagination-options="{ enabled: true, perPage: 100 }"
      >
        <template v-slot:table-row="props">
          <span :class="getCellClass(props.column.field, props.row[props.column.field])">
            <span v-if="props.column.field === 'name'">
              {{ props.row[props.column.field] }}
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </span>
        </template>
        <template v-slot:table-footer>
          <tr>
            <td>Total Votes</td>
            <td v-for="round in transformedCandidates.roundsArray" :key="round">-</td>
            <td>{{ totalVotesAllRounds }}</td>
          </tr>
        </template>
      </vue-good-table>
    </div>
    <!--<h3 class="text-secondary mt-4">Results per Round</h3>
    <div class="table-responsive">
      <vue-good-table
          :columns="candidateFields"
          :rows="transformedCandidates1"
          :search-options="{ enabled: true }"
          :sort-options="{ enabled: true }"
          :pagination-options="{ enabled: true, perPage: 50 }"
      >
        <template v-slot:table-row="props">
          <span :class="getCellClass(props.column.field, props.row[props.column.field])">
            <vue-good-table
                v-if="props.column.field === 'rounds'"
                :columns="roundFields"
                :rows="props.row.rounds"
                :search-options="{ enabled: false }"
                :sort-options="{ enabled: false }"
                :pagination-options="{ enabled: false }"
            >
              <template v-slot:table-row="roundProps">
                <span>{{ roundProps.formattedRow[roundProps.column.field] }}</span>
              </template>
            </vue-good-table>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </span>
        </template>
        <template v-slot:table-footer>
          <tr>
            <td>Total Votes</td>
            <td v-for="(total, index) in totalVotesPerRound" :key="index">{{ total }}</td>
            <td>{{ totalVotesAllRounds }}</td>
          </tr>
        </template>
      </vue-good-table>
    </div>-->
    <h3 class="text-secondary mt-4">Verified Constituency Results Data</h3>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Candidate</th>
          <th>Party</th>
          <th>Total Votes</th>
          <th>Total Votes Calc</th>
          <th>Discrepancy</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td>{{ row.Candidate }}</td>
          <td>{{ row.Party }}</td>
          <td :class="getCellClass('TotalVotes', row.TotalVotes)">{{ row.TotalVotes }}</td>
          <td :class="getCellClass('TotalVotesCalc', row.TotalVotesCalc)">{{ row.TotalVotesCalc }}</td>
          <td>{{ row.Discrepancy }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <h3 class="text-secondary mt-4">Total Votes per Round for Each Candidate</h3>
    <canvas id="roundsChart"></canvas>
    <h3 class="text-secondary mt-4">Votes per Round for Each Candidate</h3>
    <canvas id="roundsVoteChart"></canvas>
    <h3 class="text-secondary mt-4">Variance and Standard Deviation of Votes per Candidate</h3>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Candidate</th>
          <th>Variance</th>
          <th>Std Dev</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td>{{ row.Candidate }}</td>
          <td :class="getCellClass('Variance', row.Variance)">{{ row.Variance }}</td>
          <td :class="getCellClass('StdDev', row.StdDev)">{{ row.StdDev }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <h3 class="text-secondary mt-4">Correlation Matrix of Votes per Round</h3>
    <canvas id="correlationMatrix"></canvas>
    <h3 class="text-secondary mt-4">Leading Digit Distribution vs Benford's Law</h3>
    <canvas id="benfordChart"></canvas>
    <h3 class="text-secondary mt-4">Analysis Results</h3>
    <div v-for="(value, key) in analysisResults" :key="key">
      <strong>{{ key }}:</strong> {{ value }}
    </div>
    <!-- Scroll Buttons -->
    <div class="scroll-buttons">
      <button class="btn btn-primary" @click="scrollToTop">Scroll to Top</button>
      <button class="btn btn-primary" @click="scrollToBottom">Scroll to Bottom</button>
    </div>
    <footer class="footer mt-4">
      <p>&copy; 2024 Election Results. All rights reserved.</p>
      <p class="disclaimer">
        Disclaimer: The data is sourced from the Election Commission of India. We are just providing an analytical
        platform to view the detailed information.
      </p>
    </footer>
  </section>
</template>

<script>
import * as ss from 'simple-statistics';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import {VueGoodTable} from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import VotePercentageChart from './VotePercentageChart.vue';
import { Chart,registerables } from 'chart.js/auto';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';

// Register Chart.js components
Chart.register(...registerables);

// Register the matrix controller and element
Chart.register(MatrixController, MatrixElement);

const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  components: {
    VueGoodTable,
    VotePercentageChart
  },
  props: ['id'],
  data() {
    return {
      search: '',
      sortBy: '',
      sortDesc: false,
      currentPage: 1,
      constituency: {
        name: '',
        description: '',
        evmCount: 0,
        totalVoters: 0,
        candidates: [],
        demographics: {
          male: 0,
          female: 0,
          others: 0
        },
        mandals: []
      },
      resultsByRounds: [],
      highestEvmVotes: 0,
      highestPostalVotes: 0,
      highestTotalVotes: 0,
      highestPercentage: 0,
      highestRoundValues: {},
      candidateColumns: [
        {
          label: 'S.No', field: 'serialNumber', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        },
        {label: 'Name', field: 'name', sortable: true},
        {label: 'Party', field: 'party', sortable: true},
        {
          label: 'EVM Votes', field: 'votes', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        },
        {
          label: 'Postal Votes', field: 'postalVotes', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        },
        {
          label: 'Total Votes', field: 'totalVotes', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        },
        {
          label: 'Percentage', field: 'percentage', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        },
        {label: 'Winner', field: 'winner', sortable: true}
      ],
      candidateFields: [
        {
          label: 'S.No', field: 'serialNumber', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        },
        {label: 'Candidate', field: 'name', sortable: true},
        {label: 'Party', field: 'party', sortable: true},
        {label: 'Results by Rounds', field: 'rounds'},
        {
          label: 'Total Votes', field: 'totalVotes', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        }
      ],
      roundFields: [
        {label: 'Round Number', field: 'roundNumber', sortable: true},
        {
          label: 'Votes', field: 'votes', sortable: true, sortMethod(a, b) {
            return a - b;
          }
        }
      ],
      tableData: [],
      analysisResults: {},
      chartInstances: {}  // To keep track of chart instances
    };
  },
  computed: {
    dynamicTableFields() {
      const fields = [
        {label: 'S.No', field: 'serialNumber', sortable: true},
        {label: 'Candidate', field: 'name', sortable: true},
        {label: 'Party', field: 'party', sortable: true}
      ];
      this.transformedCandidates.roundsArray.forEach(round => {
        fields.push({
          label: `Round ${round}`, field: `round${round}`, sortable: true, sortMethod(a, b) {
            return a - b;
          }
        });
      });
      fields.push({
        label: 'Total Votes', field: 'totalVotes', sortable: true, sortMethod(a, b) {
          return a - b;
        }
      });
      return fields;
    },
    totalVotesAllRounds() {
      return this.transformedCandidates.transformedData.reduce((acc, candidate) => acc + candidate.totalVotes, 0);
    },
    totalEvmVotes() {
      return this.constituency.candidates.reduce((total, candidate) => total + candidate.votes, 0);
    },
    totalPostalVotes() {
      return this.constituency.candidates.reduce((total, candidate) => total + candidate.postalVotes, 0);
    },
    totalVotes() {
      return this.constituency.candidates.reduce((total, candidate) => total + candidate.totalVotes, 0);
    },
    totalVotesPercentage() {
      return this.constituency.candidates.reduce((total, candidate) => total + candidate.percentage, 0);
    },
    transformedCandidates() {
      const candidates = {};
      const roundsSet = new Set();

      this.resultsByRounds.forEach(round => {
        if (!round.roundNumber) {
          console.warn('No round number available for round:', round);
          return;
        }
        roundsSet.add(round.roundNumber);
        round.candidates.forEach(candidate => {
          if (!candidates[candidate.name]) {
            candidates[candidate.name] = {
              name: candidate.name,
              party: candidate.party,
              rounds: {},
              totalVotes: 0
            };
          }
          if (!candidates[candidate.name].rounds[round.roundNumber]) {
            candidates[candidate.name].rounds[round.roundNumber] = 0;
          }
          candidates[candidate.name].rounds[round.roundNumber] += candidate.votes;
          candidates[candidate.name].totalVotes += candidate.votes;
        });
      });

      const roundsArray = Array.from(roundsSet).sort((a, b) => a - b);
      const transformedData = Object.values(candidates).map((candidate, index) => {
        candidate.serialNumber = index + 1;
        roundsArray.forEach(roundNumber => {
          candidate[`round${roundNumber}`] = candidate.rounds[roundNumber] || 0;
        });
        return candidate;
      });

      return {transformedData, roundsArray};
    },
    /*transformedCandidates1() {
      const candidates = {};

      this.resultsByRounds.forEach(constituency => {
        constituency.rounds.forEach(round => {
          round.candidates.forEach(candidate => {
            if (!candidates[candidate.name]) {
              candidates[candidate.name] = {
                name: candidate.name,
                party: candidate.party,
                rounds: {},
                totalVotes: 0
              };
            }

            if (!candidates[candidate.name].rounds[round.roundNumber]) {
              candidates[candidate.name].rounds[round.roundNumber] = {
                roundNumber: round.roundNumber,
                votes: 0
              };
            }

            candidates[candidate.name].rounds[round.roundNumber].votes += candidate.votes;
            candidates[candidate.name].totalVotes += candidate.votes;
          });
        });
      });

      Object.values(candidates).forEach((candidate, index) => {
        candidate.serialNumber = index + 1;
        candidate.rounds = Object.values(candidate.rounds);
      });

      return Object.values(candidates);
    }*/
  },
  created() {
    this.fetchData();
    this.fetchRoundResults();
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/constituencies/${this.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.constituency = await response.json();
        this.constituency.candidates.forEach((candidate, index) => {
          candidate.serialNumber = index + 1;
        });
        this.calculateHighestValues();
      } catch (error) {
        console.error('Error fetching constituency:', error);
      }
    },
    async fetchRoundResults() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/round-results/${this.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          this.resultsByRounds = data[0].rounds;
        } else {
          console.warn('No data found in the response');
          this.resultsByRounds = [];
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        this.resultsByRounds = [];
      }
      this.initializeData();
      this.calculateHighestValues();
    },
    initializeData() {
      if (!Array.isArray(this.resultsByRounds)) {
        console.error('Invalid resultsByRounds data:', this.resultsByRounds);
        return;
      }
      if (!Array.isArray(this.constituency.candidates)) {
        console.error('Invalid candidates data:', this.constituency.candidates);
        return;
      }

      const rounds = this.resultsByRounds.map(round => `Round ${round.roundNumber}`);
      const candidates = this.constituency.candidates.map(c => c.name);

      const tableData = this.constituency.candidates.map((candidate) => {
        const candidateName = candidate.name; // Assuming candidate.name is available here

        const totalVotesCalc = this.resultsByRounds.reduce((sum, round) => {
          if (!round.candidates) {
            console.warn(`No candidates data for round: ${round.roundNumber}`);
            return sum;
          }
          const candidateRound = round.candidates.find(c => c.name === candidateName);
          if (!candidateRound) {
            console.warn(`No candidate data found for ${candidateName} in round: ${round.roundNumber}`);
          }
          return candidateRound ? sum + candidateRound.votes : sum;
        }, 0);

        const discrepancy = candidate.votes - totalVotesCalc;
        const roundVotes = this.resultsByRounds.map(round => {
          if (!round.candidates) {
            console.warn(`No candidates data for round: ${round.roundNumber}`);
            return 0;
          }
          const candidateRound = round.candidates.find(c => c.name === candidateName);
          return candidateRound ? candidateRound.votes : 0;
        });

        const variance = roundVotes.length > 0 ? ss.variance(roundVotes) : 0;
        const stdDev = roundVotes.length > 0 ? ss.standardDeviation(roundVotes) : 0;

        return {
          Candidate: candidateName,
          Party: candidate.party,
          TotalVotes: candidate.votes,
          TotalVotesCalc: totalVotesCalc,
          Discrepancy: discrepancy,
          Variance: variance,
          StdDev: stdDev,
        };
      });

// Output the tableData to verify
      console.log('Table Data:', tableData);


      this.tableData = tableData;

      this.$nextTick(() => {
        const roundsDataByTotal = this.resultsByRounds.map(round => round.candidates ? round.candidates.map(c => c.totalVotes) : []).filter(rd => rd.length > 0);
        const roundsDataByVotes = this.resultsByRounds.map(round => round.candidates ? round.candidates.map(c => c.votes) : []).filter(rd => rd.length > 0);
        if (roundsDataByTotal.length > 0) {
          this.plotRoundsChart(roundsDataByTotal, candidates, rounds);
          this.renderRoundsVoteChart(roundsDataByVotes, candidates, rounds);
          this.plotCorrelationMatrix(roundsDataByTotal);
        } else {
          console.error('Invalid roundsData:', roundsDataByTotal);
        }
        this.plotBenfordChart(this.constituency.candidates.map(c => c.totalVotes));
        this.calculateChiSquare(this.constituency.candidates.map(c => c.totalVotes));

      });
    },
    calculateChiSquare(totalVotes) {
      if (totalVotes.length === 0) {
        console.error('No data points for chi-square calculation');
        return;
      }

      const observedVotes = totalVotes;
      const expectedVotes = Array(totalVotes.length).fill(ss.mean(totalVotes));
      const chiSquareStatVotes = ss.chiSquaredGoodnessOfFit(observedVotes, expectedVotes);
      this.analysisResults = {
        "Vote Distribution Chi-Square Statistic": chiSquareStatVotes,
      };
    },
    plotRoundsChart(roundsData, candidates, rounds) {
      const canvas = document.getElementById('roundsChart');
      if (!canvas) {
        console.error('Canvas element not found');
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get canvas context');
        return;
      }

      // Destroy the previous chart instance if it exists
      if (this.chartInstances['roundsChart']) {
        this.chartInstances['roundsChart'].destroy();
      }

      this.chartInstances['roundsChart'] = new Chart(ctx, {
        type: 'line',
        data: {
          labels: rounds,
          datasets: candidates.map((candidate, index) => ({
            label: candidate,
            data: roundsData.map(round => round[index]),
            fill: false,
            borderColor: this.getRandomColor(),
            tension: 0.1,
          })),
        },
        options: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Round Number',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Votes',
              },
            },
          },
        },
      });
    },
    plotCorrelationMatrix(roundsData) {
      const transposedData = this.transpose(roundsData);

      if (!transposedData || !Array.isArray(transposedData)) {
        console.error('transposedData is not defined or not an array');
        return;
      } else {
        transposedData.forEach((row, rowIndex) => {
          if (!Array.isArray(row)) {
            console.error(`Row at index ${rowIndex} is not an array`, row);
          }
        });
      }

      // Check for any undefined or null values in transposedData
      transposedData.forEach((row, rowIndex) => {
        if (!Array.isArray(row)) {
          console.error(`transposedData[${rowIndex}] is not an array:`, row);
        } else {
          row.forEach((cell, cellIndex) => {
            if (cell === undefined || cell === null) {
              console.error(`transposedData[${rowIndex}][${cellIndex}] is ${cell}`);
            }
          });
        }
      });

// Ensure that none of the rows in transposedData are undefined or null
      const validTransposedData = transposedData.filter(row => Array.isArray(row) && row.length > 0);

      if (validTransposedData.length !== transposedData.length) {
        console.warn("Some rows were found to be invalid and have been filtered out.");
      }

      let correlationMatrix = [];
      try {
        correlationMatrix = validTransposedData.map((row) =>
            row.map((_, i) => {
              if (!Array.isArray(validTransposedData[i])) {
                console.error(`transposedData[${i}] is not an array:`, validTransposedData[i]);
                return null; // Handle the error appropriately
              }
              return ss.sampleCorrelation(row, validTransposedData[i]);
            })
        );
        console.log('Correlation Matrix:', correlationMatrix);
      } catch (error) {
        console.error('Error computing correlation matrix:', error);
      }

      const ctx = document.getElementById('correlationMatrix').getContext('2d');

      // Destroy the previous chart instance if it exists
      if (this.chartInstances['correlationMatrix']) {
        this.chartInstances['correlationMatrix'].destroy();
      }

      this.chartInstances['correlationMatrix'] = new Chart(ctx, {
        type: 'matrix',
        data: {
          labels: transposedData[0].map((_, i) => `Candidate ${i + 1}`),
          datasets: [
            {
              label: 'Correlation',
              data: correlationMatrix.flat().map((value, index) => {
                return {
                  x: Math.floor(index / transposedData.length),
                  y: index % transposedData.length,
                  v: value
                };
              }),
              backgroundColor: (ctx) => {
                const value = ctx.raw?.v;
                if (value === null || value === undefined) {
                  return 'rgba(243, 240, 180, 0.8)'; // Transparent color for null or undefined values
                }
                const alpha = Math.min(1, Math.abs(value));
                return value > 0 ? `rgba(0, 0, 255, ${alpha})` : `rgba(255, 0, 0, ${alpha})`;
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'category',
              labels: transposedData[0].map((_, i) => `Candidate ${i + 1}`),
            },
            y: {
              type: 'category',
              labels: transposedData[0].map((_, i) => `Candidate ${i + 1}`),
            },
          },
        },
      });
    },
    transpose(matrix) {
      if (!Array.isArray(matrix) || matrix.length === 0 || !Array.isArray(matrix[0])) {
        console.error('Invalid matrix data:', matrix);
        return [];
      }
      return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
    },
    plotBenfordChart(totalVotes) {
      const leadingDigits = totalVotes.map(vote => parseInt(String(vote)[0]));
      const observedFreq = leadingDigits.reduce((acc, digit) => {
        acc[digit] = (acc[digit] || 0) + 1;
        return acc;
      }, {});
      const observedData = Object.entries(observedFreq).map(([digit, freq]) => ({x: digit, y: freq}));
      const expectedFreq = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(d => Math.log10(1 + 1 / d) * totalVotes.length);
      const expectedData = expectedFreq.map((freq, index) => ({x: index + 1, y: freq}));
      const ctx = document.getElementById('benfordChart').getContext('2d');

      // Destroy the previous chart instance if it exists
      if (this.chartInstances['benfordChart']) {
        this.chartInstances['benfordChart'].destroy();
      }

      this.chartInstances['benfordChart'] = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          datasets: [
            {
              label: 'Observed',
              data: observedData,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
              label: 'Expected (Benford\'s Law)',
              type: 'line',
              data: expectedData,
              borderColor: 'rgba(255, 99, 132, 1)',
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Leading Digit',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Frequency',
              },
            },
          },
        },
      });
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    scrollToBottom() {
      window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    },
    calculateHighestValues() {
      if (this.constituency.candidates.length === 0) return;

      this.highestEvmVotes = Math.max(...this.constituency.candidates.map(c => c.votes));
      this.highestPostalVotes = Math.max(...this.constituency.candidates.map(c => c.postalVotes));
      this.highestTotalVotes = Math.max(...this.constituency.candidates.map(c => c.totalVotes));
      this.highestPercentage = Math.max(...this.constituency.candidates.map(c => c.percentage));

      this.constituency.candidates.forEach(candidate => {
        candidate.winner = candidate.percentage === this.highestPercentage;
      });

      // Calculate highest values for each round
      this.highestRoundValues = {};
      this.transformedCandidates.roundsArray.forEach(round => {
        this.highestRoundValues[round] = Math.max(...this.transformedCandidates.transformedData.map(candidate => candidate[`round${round}`]));
      });
    },
    getCellClass(field, value) {
      if (field === 'votes' || field === 'postalVotes' || field === 'totalVotes' || field.startsWith('round')) {
        const maxValues = {
          votes: this.highestEvmVotes,
          postalVotes: this.highestPostalVotes,
          totalVotes: this.highestTotalVotes,
        };
        if (field.startsWith('round')) {
          const round = field.replace('round', '');
          return value === this.highestRoundValues[round] ? 'highlight-cell' : '';
        }
        return value === maxValues[field] ? 'highlight-cell' : '';
      }
      if (field === 'percentage') {
        return value.toFixed(2) === this.highestPercentage.toFixed(2) ? 'highlight-cell' : '';
      }
      return '';
    },
    async exportToPDF() {
      const doc = new jsPDF('l', 'pt', 'a4', true); // 'p' stands for portrait
      try {
        await this.$nextTick();
        setTimeout(async () => {
          // Render the chart and convert it to an image
          const chartContainer = this.$refs.chartContainer;
          const canvas = await html2canvas(chartContainer.querySelector('canvas'));
          const chartImage = canvas.toDataURL('image/png');


          // Render the chart and convert it to an image
          //const roundByRoundChartContainer = this.$refs.roundsChart;
          const roundByRoundChartCanvas = document.getElementById('roundsChart');
          const roundByRoundChartCanvasImage = await html2canvas(roundByRoundChartCanvas);
          const roundByRoundChartImg = roundByRoundChartCanvasImage.toDataURL('image/png');

          const roundsByVotesChartCanvas = document.getElementById('roundsVoteChart');
          const roundsByVotesChartCanvasImage = await html2canvas(roundsByVotesChartCanvas);
          const roundsByVotesChartImg = roundsByVotesChartCanvasImage.toDataURL('image/png');


          const pageWidth = doc.internal.pageSize.getWidth();
          const margin = 14; // Margin from left and right sides
          const lineWidth = pageWidth - margin * 2; // Width of the line

          const titleY = 22;
          //doc.addPage(612, 791);
          //const titleWidth = doc.getStringUnitWidth(` ${this.constituency.name} Constituency Details `) * 10;
          doc.line(14, titleY - 10, 14 + lineWidth + 10, titleY - 10); // Line before title
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          doc.text(` ${this.constituency.name} Constituency Details `.toUpperCase(), 250, titleY);
          doc.line(14, titleY + 10, 14 + lineWidth + 10, titleY + 10); // Line after title

          doc.setFontSize(5);
          doc.setFont('helvetica', 'normal');
          doc.text(`Name: ${this.constituency.name}`, 14, 38);
          doc.text(`Description: ${this.constituency.description}`, 14, 46);
          doc.text(`EVM Rounds: ${this.constituency.evmCount}`, 14, 54);
          doc.text(`Total Postal Votes: ${this.totalPostalVotes}`, 14, 62);
          doc.text(`Total EVM Votes:${this.totalEvmVotes}`, 14, 70);
          doc.text(`Total Voters: ${this.totalVotes}`, 14, 78);
          doc.text(`Total Percentage of Votes:${this.totalVotesPercentage}`, 14, 86);

          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          const resultsTitleY = 100;
          //const resultsTitleWidth = doc.getStringUnitWidth(` Constituency Results `) * 10;
          doc.line(14, resultsTitleY - 10, 250 + lineWidth + 10, resultsTitleY - 10); // Line before results title
          doc.text(` Constituency Results `.toUpperCase(), 250, resultsTitleY);
          doc.line(14, resultsTitleY + 10, 250 + lineWidth + 10, resultsTitleY + 10); // Line after results title

          // doc.text(` Constituency Results `, 300, 90);
          const candidateTable = this.constituency.candidates.map((candidate, index) => ({
            serialNumber: index + 1,
            name: candidate.name,
            party: candidate.party,
            evmVotes: candidate.votes,
            postalVotes: candidate.postalVotes,
            totalVotes: candidate.totalVotes,
            percentage: candidate.percentage,
            winner: candidate.winner ? 'Yes' : 'No'
          }));

          const highestEvmVotes = Math.max(...candidateTable.map(row => row.evmVotes));
          const highestPostalVotes = Math.max(...candidateTable.map(row => row.postalVotes));
          const highestTotalVotes = Math.max(...candidateTable.map(row => row.totalVotes));
          const highestPercentage = Math.max(...candidateTable.map(row => parseFloat(row.percentage)));

          const totalsRow = {
            serialNumber: '',
            name: 'Total',
            party: '',
            evmVotes: candidateTable.reduce((acc, row) => acc + row.evmVotes, 0),
            postalVotes: candidateTable.reduce((acc, row) => acc + row.postalVotes, 0),
            totalVotes: candidateTable.reduce((acc, row) => acc + row.totalVotes, 0),
            percentage: candidateTable.reduce((acc, row) => acc + row.percentage, 0),
            winner: ''
          };

          doc.autoTable({
            startY: resultsTitleY + 40,
            head: [['S.No', 'Name', 'Party', 'EVM Votes', 'Postal Votes', 'Total Votes', 'Percentage', 'Winner']],
            body: candidateTable.map(row => [
              row.serialNumber,
              row.name,
              row.party,
              row.evmVotes,
              row.postalVotes,
              row.totalVotes,
              row.percentage,
              row.winner
            ]),
            foot: [
              [
                totalsRow.serialNumber,
                totalsRow.name,
                totalsRow.party,
                totalsRow.evmVotes,
                totalsRow.postalVotes,
                totalsRow.totalVotes,
                totalsRow.percentage,
                totalsRow.winner
              ]
            ],
            styles: {fontSize: 4, cellPadding: 2, halign: 'left', wrap: 'false'},
            headStyles: {
              fillColor: [41, 128, 185],
              textColor: [255, 255, 255]
            },
            bodyStyles: {
              halign: 'left',
              wrap: 'false'
            },
            footStyles: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontStyle: 'bold'
            },
            didParseCell: function (data) {
              if (data.row.section === 'body') {
                const colIndex = data.column.index;
                if (colIndex === 3 && data.cell.raw === highestEvmVotes) {
                  data.cell.styles.fillColor = [255, 223, 186];
                } else if (colIndex === 4 && data.cell.raw === highestPostalVotes) {
                  data.cell.styles.fillColor = [255, 223, 186];
                } else if (colIndex === 5 && data.cell.raw === highestTotalVotes) {
                  data.cell.styles.fillColor = [255, 223, 186];
                } else if (colIndex === 6 && parseFloat(data.cell.raw) === highestPercentage) {
                  data.cell.styles.fillColor = [255, 223, 186];
                }
              }
            }
          });

          const roundHeaders = ['S.No', 'Candidate', 'Party', ...this.transformedCandidates.roundsArray.map(round => `Round ${round}`), 'Total Votes'];
          const roundTable = this.transformedCandidates.transformedData.map((candidate, index) => {
            const rounds = this.transformedCandidates.roundsArray.map(round => candidate[`round${round}`] || 0);
            return [
              index + 1,
              candidate.name,
              candidate.party,
              ...rounds,
              candidate.totalVotes
            ];
          });

          const highestValues = {};
          this.transformedCandidates.roundsArray.forEach((round, index) => {
            highestValues[round] = Math.max(...roundTable.map(row => row[index + 3]));
          });

          const roundTotalsRow = [
            '',
            'Total',
            '',
            ...this.transformedCandidates.roundsArray.map((round, index) =>
                roundTable.reduce((acc, row) => acc + row[index + 3], 0)
            ),
            roundTable.reduce((acc, row) => acc + row[row.length - 1], 0)
          ];

          doc.addPage();
          doc.setPage(2);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          //const graphTitleY = doc.previousAutoTable.finalY + 50;
          let graphTitleY = 40;
          //const graphTitleWidth = doc.getStringUnitWidth(` Results Percentage Graph `) * 10;
          doc.line(14, graphTitleY - 10, 250 + lineWidth + 10, graphTitleY - 10); // Line before results title
          doc.text(` Results Percentage Graph`.toUpperCase(), 250, graphTitleY);
          doc.line(14, graphTitleY + 10, 250 + lineWidth + 10, graphTitleY + 10); // Line after results title
          //doc.text(` Results Percentage Graph `, 300, doc.previousAutoTable.finalY + 60);
          doc.addImage(chartImage, 'PNG', 14, graphTitleY + 100, pageWidth - 100, doc.internal.pageSize.height - 200); // Adjust the position and size as needed

          doc.addPage();
          doc.setPage(3);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          //const roundResultsTitleY = doc.previousAutoTable.finalY + 370;
          const roundResultsTitleY = 40;
          // const roundTitleWidth = doc.getStringUnitWidth(` Constituency Results By Rounds `) * 10;
          doc.line(14, roundResultsTitleY - 10, 250 + lineWidth + 10, roundResultsTitleY - 10); // Line before results title
          doc.text(` Constituency Results By Rounds`.toUpperCase(), 250, roundResultsTitleY);
          doc.line(14, roundResultsTitleY + 10, 250 + lineWidth + 10, roundResultsTitleY + 10); // Line after results title
          //doc.text(` Constituency Results By Rounds`, 300, doc.previousAutoTable.finalY + 300);
          doc.autoTable({
            startY: roundResultsTitleY + 80,
            head: [roundHeaders],
            body: roundTable,
            foot: [roundTotalsRow],
            styles: {fontSize: 4, cellPadding: 2, halign: 'left', wrap: 'false'},
            headStyles: {
              fillColor: [41, 128, 185],
              textColor: [255, 255, 255]
            },
            bodyStyles: {
              halign: 'left',
              wrap: 'false'
            },
            footStyles: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontStyle: 'bold'
            },
            didParseCell: function (data) {
              if (data.row.section === 'body' && data.column.index > 2 && data.column.index <= this.transformedCandidates.roundsArray.length + 2) {
                const roundIndex = data.column.index - 3;
                const roundNumber = this.transformedCandidates.roundsArray[roundIndex];
                if (data.cell.raw === highestValues[roundNumber]) {
                  data.cell.styles.fillColor = [255, 223, 186];
                }
              }
            }.bind(this)
          });

          doc.addPage();
          doc.setPage(4);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          //const graphTitleY = doc.previousAutoTable.finalY + 50;
          graphTitleY = 40;
          //const graphTitleWidth = doc.getStringUnitWidth(` Results Percentage Graph `) * 10;
          doc.line(14, graphTitleY - 10, 250 + lineWidth + 10, graphTitleY - 10); // Line before results title
          doc.text(` Results Graph By Rounds`.toUpperCase(), 250, graphTitleY);
          doc.line(14, graphTitleY + 10, 250 + lineWidth + 10, graphTitleY + 10); // Line after results title
          //doc.text(` Results Percentage Graph `, 300, doc.previousAutoTable.finalY + 60);
          doc.addImage(roundByRoundChartImg, 'PNG', 14, graphTitleY + 100, pageWidth - 100, doc.internal.pageSize.height - 200);


          doc.addPage();
          doc.setPage(5);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          //const graphTitleY = doc.previousAutoTable.finalY + 50;
          graphTitleY = 40;
          //const graphTitleWidth = doc.getStringUnitWidth(` Results Percentage Graph `) * 10;
          doc.line(14, graphTitleY - 10, 250 + lineWidth + 10, graphTitleY - 10); // Line before results title
          doc.text(` Votes Graph By Rounds`.toUpperCase(), 250, graphTitleY);
          doc.line(14, graphTitleY + 10, 250 + lineWidth + 10, graphTitleY + 10); // Line after results title
          //doc.text(` Results Percentage Graph `, 300, doc.previousAutoTable.finalY + 60);
          doc.addImage(roundsByVotesChartImg, 'PNG', 14, graphTitleY + 100, pageWidth - 100, doc.internal.pageSize.height - 200);

          doc.save(`constituency_details_${this.constituency.name}.pdf`);
        }, 500); // Adjust the delay as needed
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    },
    renderRoundsVoteChart(roundsData, candidates, rounds) {


      rounds = rounds.sort((a, b) => a - b);
      //roundsData = Array.from(candidates, ([name, votes]) => ({ name, votes }));

      const ctx = document.getElementById('roundsVoteChart').getContext('2d');


      // Destroy the previous chart instance if it exists
      if (this.chartInstances['roundsVoteChart']) {
        this.chartInstances['roundsVoteChart'].destroy();
      }

      this.chartInstances['roundsVoteChart'] = new Chart(ctx, {
            type: 'line',
            data: {
              labels: rounds,
              datasets: candidates.map((candidate, index) => ({
                label: candidate,
                data: roundsData.map(round => round[index]),
                fill: false,
                borderColor: this.getRandomColor(),
                tension: 0.1,
              })),
            },
            options: {
              responsive: true,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Round Number',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Votes',
                  },
                },
              },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            title: {
              display: true,
              text: 'Vote Counts Over Rounds'
            }
          }
        },
      });
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

section {
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

h2, h3 {
  color: #1f3c88;
}

.breadcrumb {
  background-color: #f8f9fa;
  padding: 10px 15px;
  margin-bottom: 20px;
  list-style: none;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 1.1em;
  margin: 10px 0;
}

.highlight-cell {
  background-color: #ffd7b5; /* Light orange color */
}

a {
  display: inline-block;
  color: #42b983;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, background-color 0.3s;
}

a:hover {
  color: #1f3c88;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px;
}

.scroll-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.description {
  font-size: 1.1em;
  margin-bottom: 1rem;
}

.container {
  padding-top: 10px; /* Reduce top white space */
}

.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 20px;
}

.disclaimer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}

.table-wrapper {
  overflow-x: auto;
}

.table-wrapper table {
  width: 100%;
  table-layout: fixed;
}

.table-responsive {
  overflow-x: auto;
}

.vue-good-table-wrapper .table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.vue-good-table-wrapper .table th, .vue-good-table-wrapper .table td {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.vue-good-table-wrapper .table th {
  background-color: #f8f9fa;
}

.vue-good-table-wrapper .table th.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f8f9fa;
}

.vue-good-table-wrapper .table td.sticky {
  position: sticky;
  left: 0;
  z-index: 1;
  background: #fff;
}

.highlight-cell {
  background-color: #ffd7b5; /* Light orange color */
}

</style>
