import { createStore } from 'vuex';
import constituency from './modules/constituency';
import mandals from './modules/mandals';
import gramPanchayat from './modules/gramPanchayat';
import ward from './modules/ward';
import pollingStation from './modules/pollingStation';

export default createStore({
    modules: {
        constituency,
        mandals,
        gramPanchayat,
        ward,
        pollingStation
    }
});
