<template>
  <canvas id="votePercentageChart"></canvas>
</template>

<script>
import { Chart } from 'chart.js/auto';

export default {
  props: {
    candidates: {
      type: Array,
      required: true
    }
  },
  watch: {
    candidates: {
      handler: 'renderChart',
      deep: true
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      const ctx = document.getElementById('votePercentageChart').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: this.candidates.map(candidate => candidate.name),
          datasets: [{
            data: this.candidates.map(candidate => candidate.percentage),
            backgroundColor: this.candidates.map(() => this.getRandomColor())
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Vote Percentage by Candidate'
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const candidate = this.candidates[tooltipItem.dataIndex];
                  const party = candidate.party;
                  const percentage = candidate.percentage;
                  return `${party}: ${percentage}%`;
                }
              }
            }
          }
        }
      });
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}
</style>
