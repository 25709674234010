<template>
  <div>
    <h2>Party: {{ name }}</h2>
    <p class="description">
      This page provides detailed results for the selected party, including the constituencies they contested in, their candidates, the number of votes each candidate received, and the overall result (win or loss) for each candidate.
    </p>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ name }} Results</li>
      </ol>
    </nav>
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true }"
        :sort-options="{ enabled: true }"
        :pagination-options="{ enabled: true, perPage: 100 }"
    >
      <template v-slot:table-row="props">
        <span v-if="props.column.field === 'constituency'">
          <router-link :to="'/constituencies/' + props.row.constituencyId">{{ props.row.constituency }}</router-link>
        </span>
        <span v-else-if="props.column.field === 'candidate'">
          <router-link :to="'/candidates/' + props.row.constituencyId +  '/candidate/' + props.row.candidate">{{ props.row.candidate }}</router-link>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <footer class="footer mt-4">
      <p>&copy; 2024 Election Results. All rights reserved.</p>
      <p class="disclaimer">
        Disclaimer: The data is sourced from the Election Commission of India. We are just providing an analytical platform to view the detailed information.
      </p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';

const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  components: {
    VueGoodTable
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      columns: [
        { label: 'Constituency', field: 'constituency' },
        { label: 'Candidate', field: 'candidate' },
        { label: 'Votes', field: 'votes', type: 'number', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          } },
        { label: 'Result', field: 'result' } // Added the Result column
      ],
      rows: []
    };
  },
  mounted() {
    this.fetchPartyDetails();
  },
  methods: {
    async fetchPartyDetails() {
      try {
        const encodedPartyName = encodeURIComponent(this.name);
        console.log(`Fetching details for party: ${this.name}`); // Debug line
        const response = await axios.get(`${apiBaseUrl}/elections/constituencies-all/party-details/${encodedPartyName}`);

        // Process each constituency to determine the winner
        this.rows = response.data.flatMap(constituency => {
          // Find the candidate with the highest percentage of votes
          let maxPercentage = 0;
          let winningCandidateId = null;

          constituency.candidates.forEach(candidate => {
            if (candidate.percentage > maxPercentage) {
              maxPercentage = candidate.percentage;
              winningCandidateId = candidate.candidateId;
            }
          });

          // Map candidates and set the winner field based on the candidateId
          return constituency.candidates.filter(candidate => candidate.party === this.name).map(candidate => ({
            constituency: constituency.name,
            candidate: candidate.name,
            votes: candidate.totalVotes,
            result: candidate.candidateId === winningCandidateId ? 'Won' : 'Lost',
            constituencyId: constituency.constituencyId,
            candidateId: candidate.candidateId
          }));
        });
      } catch (error) {
        console.error('Error fetching party details:', error);
      }
    }
  }
};
</script>

<style scoped>
/* Add any necessary styling */
.description {
  font-size: 1.1em;
  margin-bottom: 1rem;
}
.container {
  padding-top: 10px; /* Reduce top white space */
}
.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 20px;
}
.disclaimer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}
</style>
