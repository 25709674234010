<template>
  <div class="container mt-5">
    <h1 class="text-primary mb-4">Candidate Results</h1>
    <p class="description">
      Use the dropdowns below to select a round and one or more constituencies to view the candidate results. Click the "Refresh" button to pull the latest results.
    </p>
    <div class="form-group mb-4 d-flex justify-content-between">
      <div class="select-container">
        <label for="roundSelect" class="form-label">Select Round:</label>
        <select id="roundSelect" v-model="selectedRound" @change="fetchResults" class="form-select">
          <option v-for="round in rounds" :key="round" :value="round">{{ round }}</option>
        </select>
      </div>
      <div class="select-container">
        <label for="constituencySelect" class="form-label">Select Constituency:</label>
        <multiselect
            id="constituencySelect"
            v-model="selectedConstituencies"
            :options="constituencies"
            :multiple="true"
            :label="'name'"
            track-by="id"
            @change="fetchResults"
            placeholder="Select constituencies"
            class="form-control"
        />
      </div>
      <button class="btn btn-primary align-self-end" @click="fetchResults">Refresh</button>
    </div>
    <vue-good-table
        :columns="columns"
        :rows="results"
        :search-options="{ enabled: true }"
        :sort-options="{ enabled: true }"
        :pagination-options="{ enabled: true, perPage: 100 }"
        class="mt-4"
    >
      <template v-slot:table-row="props">
        <span v-if="props.column.field === 'constituencyName'">
          <router-link :to="'/constituency/' + props.row.constituencyName">{{ props.row.constituencyName }}</router-link>
        </span>
        <span v-else-if="props.column.field === 'name'">
          <router-link :to="'/candidates/' + getConstituencyId(props.row.constituencyName) + '/candidate/' + props.row.name">{{ props.row.name }}</router-link>
        </span>
        <span v-else-if="props.column.field === 'party'">
          <router-link :to="'/party/' + props.row.party">{{ props.row.party }}</router-link>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <footer class="footer mt-4">
      <p>&copy; 2024 Election Results. All rights reserved.</p>
      <p class="disclaimer">
        Disclaimer: The data is sourced from the Election Commission of India. We are just providing an analytical platform to view the detailed information.
      </p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  components: {
    VueGoodTable,
    Multiselect
  },
  data() {
    return {
      selectedRound: 1,
      selectedConstituencies: [],
      rounds: Array.from({ length: 25 }, (_, i) => i + 1), // Example round numbers
      constituencies: [],
      constituencyMap: {},
      results: [],
      columns: [
        { label: 'Round', field: 'roundNumber' },
        { label: 'Constituency', field: 'constituencyName' },
        { label: 'Name', field: 'name' },
        { label: 'Party', field: 'party' },
        { label: 'Votes', field: 'votes' }
      ]
    };
  },
  created() {
    this.fetchConstituencies();
  },
  methods: {
    async fetchResults() {
      if (!this.selectedRound || this.selectedConstituencies.length === 0) return;
      try {
        const response = await axios.get(`${apiBaseUrl}/elections/round-results-by-round`, {
          params: {
            roundNumber: this.selectedRound,
            constituencies: this.selectedConstituencies.map(c => c.id).join(',')
          }
        });
        this.results = response.data.flatMap(result =>
            result.rounds[0].candidates.map(candidate => ({
              ...candidate,
              roundNumber: result.rounds[0].roundNumber,
              constituencyName: this.constituencyMap[result.constituencyId] || result.constituencyId
            }))
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchConstituencies() {
      try {
        const response = await axios.get(`${apiBaseUrl}/elections/constituencies-list`);
        this.constituencies = response.data.map(constituency => ({
          id: constituency.id,
          name: constituency.name
        }));
        this.constituencyMap = this.constituencies.reduce((map, constituency) => {
          map[constituency.id] = constituency.name;
          return map;
        }, {});
      } catch (error) {
        console.error(error);
      }
    },
    getConstituencyId(constituencyName) {
      const constituency = this.constituencies.find(c => c.name === constituencyName);
      return constituency ? constituency.id : '';
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.d-flex {
  display: flex;
  gap: 20px;
}

.select-container {
  flex: 1;
}

.form-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-select,
.form-control {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.form-select:focus,
.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.mt-4 {
  margin-top: 1.5rem;
}

.router-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.router-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn:hover {
  background-color: #0056b3;
}

.align-self-end {
  align-self: flex-end;
}

.description {
  font-size: 1.1em;
  margin-bottom: 1rem;
}
.container {
  padding-top: 10px; /* Reduce top white space */
}
.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 20px;
}
.disclaimer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}

</style>
