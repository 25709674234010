<template>
  <div>
    <h1>{{ isEditMode ? 'Edit' : 'Add New' }} Constituency</h1>
    <form @submit.prevent="submitForm">
      <div>
        <label>Name</label>
        <input type="text" v-model="constituency.name" required />
      </div>
      <div>
        <label>Description</label>
        <input type="text" v-model="constituency.description" required />
      </div>
      <div>
        <label>Total Voters</label>
        <input type="number" v-model="constituency.totalVoters" required />
      </div>
      <div>
        <label>EVM Count</label>
        <input type="number" v-model="constituency.evmCount" required />
      </div>
      <!-- Add other fields as necessary -->
      <button type="submit">{{ isEditMode ? 'Update' : 'Create' }}</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ConstituencyForm',
  data() {
    return {
      constituency: {
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        // Initialize other fields as necessary
      },
      isEditMode: false
    };
  },
  created() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.fetchConstituency(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions(['fetchConstituency', 'createConstituency', 'updateConstituency']),
    fetchConstituency(id) {
      this.$store.dispatch('fetchConstituency', id).then(response => {
        this.constituency = response;
      });
    },
    submitForm() {
      if (this.isEditMode) {
        this.$store.dispatch('updateConstituency', {
          id: this.$route.params.id,
          data: this.constituency
        }).then(() => {
          this.$router.push('/constituencies-all');
        });
      } else {
        this.$store.dispatch('createConstituency', this.constituency).then(() => {
          this.$router.push('/constituencies-all');
        });
      }
    }
  }
};
</script>
