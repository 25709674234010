<template>
  <header>
    <nav>
      <img src="../assets/apspa_logo.png" alt="APEPA" className="nav-logo">
      <router-link to="/constituencies">Results By Constituencies</router-link>
      <router-link to="/total-results">Results By Parties</router-link>
      <router-link to="/round-results-by-round">Results By Rounds</router-link>

      <!--router-link to="/mandals">Mandals</router-link>
      <router-link to="/mandals-v1">Mandals V1</router-link>
      <router-link to="/gram-panchayats">Gram Panchayats</router-link>
      <router-link to="/wards">Wards</router-link>
      <router-link to="/polling-stations">Polling Stations</router-link>
      <router-link to="/election-results">Election Results</router-link-->
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Navbar'
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

header {
  background-color: #1f3c88; /* Darker blue */
  padding: 10px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  font-family: 'Roboto', sans-serif;
}

nav {
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center align the navigation items */
  gap: 30px; /* Space between the links */
}

.nav-logo {
  height: 30px; /* Adjust the logo size */
  margin-right: 20px; /* Space between the logo and the first link */
}

router-link {
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
  color: white;
  text-decoration: none;
  font-weight: 500; /* Medium weight */
  font-size: 1.2em; /* Larger font size */
  padding: 5px 10px;
  transition: color 0.3s, background-color 0.3s; /* Smooth transition for hover effects */
}

router-link:hover {
  color: #1f3c88; /* Text color on hover */
  background-color: white; /* Background color on hover */
  border-radius: 5px; /* Rounded corners */
}

router-link.active {
  text-decoration: underline;
  font-weight: 700; /* Bold weight for active link */
}
</style>
