<template>
  <div class="container mt-4">
    <h1 class="mb-4">Wards</h1>
    <router-link to="/wards/new" class="btn btn-primary mb-3">Add New Ward</router-link>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Description</th>
        <th scope="col">Total Voters</th>
        <th scope="col">EVM Count</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(ward, index) in allWards" :key="ward.wardId">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ ward.name }}</td>
        <td>{{ ward.description }}</td>
        <td>{{ ward.totalVoters }}</td>
        <td>{{ ward.evmCount }}</td>
        <td>
          <router-link :to="{ name: 'WardEdit', params: { id: ward.wardId } }" class="btn btn-sm btn-warning me-2">Edit</router-link>
          <button @click="deleteWard(ward.wardId)" class="btn btn-sm btn-danger">Delete</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'WardList',
  computed: {
    ...mapGetters(['allWards'])
  },
  created() {
    this.fetchWards();
  },
  methods: {
    ...mapActions(['fetchWards', 'deleteWard'])
  }
};
</script>
