<template>
  <section>
    <h2>Regions</h2>
    <nav aria-label="breadcrumb">
      <ul class="breadcrumb-vertical">
        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Mandals</li>
      </ul>
    </nav>
    <table v-if="mandals.length">
      <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Details</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="region in mandals" :key="region.regionId">
        <td>{{ region.name }}</td>
        <td>{{ region.description }}</td>
        <td>
          <router-link :to="{ name: 'RegionDetails', params: { id: region.regionId }}">
            View Details
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <p v-else>Loading mandals...</p>
  </section>
</template>

<script>
const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  data() {
    return {
      mandals: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/mandals-v1`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.mandals = await response.json();
      } catch (error) {
        console.error('Error fetching mandals:', error);
      }
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

section {
  width: 80%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  text-align: left; /* Align content to the left */
}

h2 {
  color: #1f3c88; /* Darker blue for headers */
  text-align: center; /* Center align headers */
}

.breadcrumb-vertical {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  list-style: none;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
  align-self: center;
}

.breadcrumb-item {
  margin-bottom: 10px;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 1.1em; /* Slightly larger text */
  margin: 10px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

a {
  display: inline-block;
  color: #42b983;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, background-color 0.3s;
}

a:hover {
  color: #1f3c88;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px;
}
</style>
