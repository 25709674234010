<template>
  <div>
    <PollingStationList />
  </div>
</template>

<script>
import PollingStationList from '@/components/PollingStationList';

export default {
  name: 'PollingStationListView',
  components: {
    PollingStationList
  }
};
</script>
