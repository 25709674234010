<template>
  <section>
    <h2>Election Results for {{ constituency.name }}</h2>
    <nav aria-label="breadcrumb">
      <ul class="breadcrumb-vertical">
        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
        <li class="breadcrumb-item"><router-link to="/constituencies">Constituencies</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Election Results</li>
      </ul>
    </nav>
    <p>Number of Regions: {{ constituency.mandals.length }}</p>
    <table v-if="results.length">
      <thead>
      <tr>
        <th>Constituency ID</th>
        <th>Total Votes</th>
        <th>Candidate</th>
        <th>Party</th>
        <th>Votes</th>
        <th>Region</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="result in results" :key="result.resultId">
        <td>{{ result.constituencyId }}</td>
        <td>{{ result.totalVotes }}</td>
        <td colspan="3">
          <table class="inner-table">
            <tbody>
            <tr v-for="candidate in result.candidates" :key="candidate.candidateId">
              <td>{{ candidate.name }}</td>
              <td>{{ candidate.party }}</td>
              <td>{{ candidate.votes }}</td>
            </tr>
            </tbody>
          </table>
        </td>
        <td>
          <router-link :to="{ name: 'RegionDetails', params: { id: result.regionId }}">
            {{ getRegionName(result.regionId) }}
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <p v-else>Loading election results...</p>
  </section>
</template>

<script>

const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  props: ['id'],
  data() {
    return {
      constituency: {
        name: '',
        mandals: []
      },
      results: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Fetch Constituency Details
        const constituencyResponse = await fetch(`${apiBaseUrl}/elections/constituencies/${this.id}`);
        if (!constituencyResponse.ok) {
          throw new Error(`HTTP error! status: ${constituencyResponse.status}`);
        }
        this.constituency = await constituencyResponse.json();

        // Fetch Election Results
        const resultsResponse = await fetch(`${apiBaseUrl}/elections/election-results/${this.id}`);
        if (!resultsResponse.ok) {
          throw new Error(`HTTP error! status: ${resultsResponse.status}`);
        }
        this.results = await resultsResponse.json();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    getRegionName(regionId) {
      const region = this.constituency.mandals.find(region => region.regionId === regionId);
      return region ? region.name : 'Unknown Region';
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

section {
  width: 80%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  text-align: left; /* Align content to the left */
}

h2 {
  color: #1f3c88; /* Darker blue for headers */
  text-align: center; /* Center align headers */
}

.breadcrumb-vertical {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  list-style: none;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
  align-self: center;
}

.breadcrumb-item {
  margin-bottom: 10px;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 1.1em; /* Slightly larger text */
  margin: 10px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

.inner-table {
  width: 100%;
  border-collapse: collapse;
}

.inner-table th, .inner-table td {
  border: none;
  padding: 4px 8px;
}

a {
  display: inline-block;
  color: #42b983;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, background-color 0.3s;
}

a:hover {
  color: #1f3c88;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px;
}
</style>
