<template>
  <div class="container mt-4">
    <PartyDetails :name="name"/>
  </div>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import PartyDetails  from "@/components/PartyDetails.vue";

export default {
  props: ['name'],
  name: 'PartyDetailsView',
  components: {
    PartyDetails
  }
};

</script>

<style scoped>
/* Add any necessary styling */
</style>
