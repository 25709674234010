<template>
  <div class="container mt-4">
    <WardList />
  </div>
</template>

<script>
import WardList from '@/components/WardList';

export default {
  name: 'WardListView',
  components: {
    WardList
  }
};
</script>
