<template>
  <div>
    <ListConstituency />
  </div>
</template>

<script>

import ListConstituency from "@/components/ListConstituency";

export default {
  name: 'ConstituencyListView',
  components: {
    ListConstituency
  }
};
</script>
