<template>
  <div class="container mt-4">
    <h2>{{ isEditMode ? 'Edit' : 'Add New' }} Ward</h2>
    <form @submit.prevent="submitForm" class="row g-3">
      <div class="col-md-6">
        <label for="name" class="form-label">Name</label>
        <input type="text" id="name" v-model="ward.name" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="description" class="form-label">Description</label>
        <input type="text" id="description" v-model="ward.description" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="totalVoters" class="form-label">Total Voters</label>
        <input type="number" id="totalVoters" v-model="ward.totalVoters" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="evmCount" class="form-label">EVM Count</label>
        <input type="number" id="evmCount" v-model="ward.evmCount" class="form-control" required />
      </div>

      <!-- Demographics Section -->
      <h3>Demographics</h3>
      <div class="col-md-4">
        <label for="male" class="form-label">Male</label>
        <input type="number" id="male" v-model="ward.demographics.male" class="form-control" required />
      </div>
      <div class="col-md-4">
        <label for="female" class="form-label">Female</label>
        <input type="number" id="female" v-model="ward.demographics.female" class="form-control" required />
      </div>
      <div class="col-md-4">
        <label for="others" class="form-label">Others</label>
        <input type="number" id="others" v-model="ward.demographics.others" class="form-control" required />
      </div>

      <!-- Candidates Section -->
      <h3>Candidates</h3>
      <div v-for="(candidate, index) in ward.candidates" :key="index" class="row mb-3">
        <div class="col-md-6">
          <label :for="'candidate-name-' + index" class="form-label">Name</label>
          <input type="text" :id="'candidate-name-' + index" v-model="candidate.name" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'candidate-party-' + index" class="form-label">Party</label>
          <input type="text" :id="'candidate-party-' + index" v-model="candidate.party" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'candidate-votes-' + index" class="form-label">Votes</label>
          <input type="number" :id="'candidate-votes-' + index" v-model="candidate.votes" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'candidate-postalVotes-' + index" class="form-label">Postal Votes</label>
          <input type="number" :id="'candidate-postalVotes-' + index" v-model="candidate.postalVotes" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'candidate-totalVotes-' + index" class="form-label">Total Votes</label>
          <input type="number" :id="'candidate-totalVotes-' + index" v-model="candidate.totalVotes" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'candidate-percentage-' + index" class="form-label">Percentage</label>
          <input type="number" :id="'candidate-percentage-' + index" v-model="candidate.percentage" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'candidate-winner-' + index" class="form-label">Winner</label>
          <input type="checkbox" :id="'candidate-winner-' + index" v-model="candidate.winner" class="form-check-input" />
        </div>
        <button type="button" class="btn btn-danger mt-2" @click="removeCandidate(index)">Remove Candidate</button>
      </div>
      <button type="button" class="btn btn-secondary mb-3" @click="addCandidate">Add Candidate</button>

      <!-- Polling Stations Section -->
      <h3>Polling Stations</h3>
      <div v-for="(pollingStation, index) in ward.pollingStations" :key="index" class="row mb-3">
        <div class="col-md-6">
          <label :for="'pollingStation-name-' + index" class="form-label">Name</label>
          <input type="text" :id="'pollingStation-name-' + index" v-model="pollingStation.name" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'pollingStation-description-' + index" class="form-label">Description</label>
          <input type="text" :id="'pollingStation-description-' + index" v-model="pollingStation.description" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'pollingStation-totalVoters-' + index" class="form-label">Total Voters</label>
          <input type="number" :id="'pollingStation-totalVoters-' + index" v-model="pollingStation.totalVoters" class="form-control" required />
        </div>
        <div class="col-md-6">
          <label :for="'pollingStation-evmCount-' + index" class="form-label">EVM Count</label>
          <input type="number" :id="'pollingStation-evmCount-' + index" v-model="pollingStation.evmCount" class="form-control" required />
        </div>
        <!-- Polling Station Demographics -->
        <h4>Demographics</h4>
        <div class="col-md-4">
          <label :for="'pollingStation-male-' + index" class="form-label">Male</label>
          <input type="number" :id="'pollingStation-male-' + index" v-model="pollingStation.demographics.male" class="form-control" required />
        </div>
        <div class="col-md-4">
          <label :for="'pollingStation-female-' + index" class="form-label">Female</label>
          <input type="number" :id="'pollingStation-female-' + index" v-model="pollingStation.demographics.female" class="form-control" required />
        </div>
        <div class="col-md-4">
          <label :for="'pollingStation-others-' + index" class="form-label">Others</label>
          <input type="number" :id="'pollingStation-others-' + index" v-model="pollingStation.demographics.others" class="form-control" required />
        </div>
        <!-- Polling Station Candidates -->
        <h4>Candidates</h4>
        <div v-for="(candidate, cIndex) in pollingStation.candidates" :key="cIndex" class="row mb-3">
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-name-' + index + '-' + cIndex" class="form-label">Name</label>
            <input type="text" :id="'pollingStation-candidate-name-' + index + '-' + cIndex" v-model="candidate.name" class="form-control" required />
          </div>
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-party-' + index + '-' + cIndex" class="form-label">Party</label>
            <input type="text" :id="'pollingStation-candidate-party-' + index + '-' + cIndex" v-model="candidate.party" class="form-control" required />
          </div>
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-votes-' + index + '-' + cIndex" class="form-label">Votes</label>
            <input type="number" :id="'pollingStation-candidate-votes-' + index + '-' + cIndex" v-model="candidate.votes" class="form-control" required />
          </div>
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-postalVotes-' + index + '-' + cIndex" class="form-label">Postal Votes</label>
            <input type="number" :id="'pollingStation-candidate-postalVotes-' + index + '-' + cIndex" v-model="candidate.postalVotes" class="form-control" required />
          </div>
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-totalVotes-' + index + '-' + cIndex" class="form-label">Total Votes</label>
            <input type="number" :id="'pollingStation-candidate-totalVotes-' + index + '-' + cIndex" v-model="candidate.totalVotes" class="form-control" required />
          </div>
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-percentage-' + index + '-' + cIndex" class="form-label">Percentage</label>
            <input type="number" :id="'pollingStation-candidate-percentage-' + index + '-' + cIndex" v-model="candidate.percentage" class="form-control" required />
          </div>
          <div class="col-md-6">
            <label :for="'pollingStation-candidate-winner-' + index + '-' + cIndex" class="form-label">Winner</label>
            <input type="checkbox" :id="'pollingStation-candidate-winner-' + index + '-' + cIndex" v-model="candidate.winner" class="form-check-input" />
          </div>
          <button type="button" class="btn btn-danger mt-2" @click="removePollingStationCandidate(index, cIndex)">Remove Candidate</button>
        </div>
        <button type="button" class="btn btn-secondary mb-3" @click="addPollingStationCandidate(index)">Add Candidate</button>
        <button type="button" class="btn btn-danger mt-2" @click="removePollingStation(index)">Remove Polling Station</button>
      </div>
      <button type="button" class="btn btn-secondary mb-3" @click="addPollingStation">Add Polling Station</button>

      <div class="col-12">
        <button type="submit" class="btn btn-primary me-2">{{ isEditMode ? 'Update' : 'Create' }}</button>
        <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WardForm',
  data() {
    return {
      ward: {
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        demographics: {
          demographicId: '',
          male: 0,
          female: 0,
          others: 0
        },
        candidates: [],
        pollingStations: []
      },
      isEditMode: false
    };
  },
  created() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.fetchWard(this.$route.params.id).then(response => {
        this.ward = response;
      });
    }
  },
  methods: {
    ...mapActions(['fetchWard', 'createWard', 'updateWard']),
    submitForm() {
      if (this.isEditMode) {
        this.updateWard({
          id: this.$route.params.id,
          data: this.ward
        }).then(() => {
          this.$router.push('/wards');
        });
      } else {
        this.createWard(this.ward).then(() => {
          this.$router.push('/wards');
        });
      }
    },
    cancel() {
      this.$router.push('/wards');
    },
    addCandidate() {
      this.ward.candidates.push({
        candidateId: '',
        name: '',
        party: '',
        votes: 0,
        postalVotes: 0,
        totalVotes: 0,
        percentage: 0,
        winner: false
      });
    },
    removeCandidate(index) {
      this.ward.candidates.splice(index, 1);
    },
    addPollingStation() {
      this.ward.pollingStations.push({
        pollingStationId: '',
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        demographics: {
          demographicId: '',
          male: 0,
          female: 0,
          others: 0
        },
        candidates: []
      });
    },
    removePollingStation(index) {
      this.ward.pollingStations.splice(index, 1);
    },
    addPollingStationCandidate(pollingStationIndex) {
      this.ward.pollingStations[pollingStationIndex].candidates.push({
        candidateId: '',
        name: '',
        party: '',
        votes: 0,
        postalVotes: 0,
        totalVotes: 0,
        percentage: 0,
        winner: false
      });
    },
    removePollingStationCandidate(pollingStationIndex, candidateIndex) {
      this.ward.pollingStations[pollingStationIndex].candidates.splice(candidateIndex, 1);
    }
  }
};
</script>
