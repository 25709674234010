<template>
  <section v-if="region && constituency">
    <h2>{{ region.name }} Details</h2>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
        <li class="breadcrumb-item"><router-link to="/constituencies">Constituencies</router-link></li>
        <li class="breadcrumb-item"><router-link :to="{ name: 'ConstituencyDetails', params: { id: constituency.constituencyId }}">{{ constituency.name }}</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">{{ region.name }}</li>
      </ol>
    </nav>
    <p>{{ region.description }}</p>
    <p>Constituency: {{ constituency.name }}</p>
    <p>Constituency Description: {{ constituency.description }}</p>
  </section>
  <p v-else>Loading...</p>
</template>

<script>
const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  props: ['id'],
  data() {
    return {
      region: '',
      constituency: ''
    };
  },
  created() {
    this.fetchRegionData();
  },
  methods: {
    async fetchRegionData() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/mandals-v1/${this.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const regionData = await response.json();
        this.region = regionData;
        this.fetchConstituencyData(regionData.constituencyId);
      } catch (error) {
        console.error('Error fetching region:', error);
      }
    },
    async fetchConstituencyData(constituencyId) {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/constituencies/${constituencyId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.constituency = await response.json();
      } catch (error) {
        console.error('Error fetching constituency:', error);
      }
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

section {
  width: 80%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  text-align: left; /* Align content to the left */
}

h2 {
  color: #1f3c88; /* Darker blue for headers */
  text-align: center; /* Center align headers */
}

.breadcrumb {
  background-color: #f8f9fa;
  padding: 10px 15px;
  margin-bottom: 20px;
  list-style: none;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 1.1em; /* Slightly larger text */
  margin: 10px 0;
}
</style>
