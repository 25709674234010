<template>
  <div class="container mt-4">
    <CandidateDetails :constituencyId="constituencyId" :candidateName="candidateName"/>
  </div>
</template>

<script>
import CandidateDetails from "../components/CandidateDetails.vue";

export default {
  props: ['constituencyId', 'candidateName'],
  name: 'CandidateDetailsView',
  components: {
    CandidateDetails
  }
};
</script>
