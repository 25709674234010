<template>
  <div class="container mt-4">
    <h2>Election Results By Political Parties</h2>
    <p class="description">
      This page displays detailed election results categorized by political parties. You can see the total votes, percentage of votes, constituencies contested, and constituencies won by each party. The table is interactive, allowing you to search, sort, and navigate to detailed pages for each party.
    </p>
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true }"
        :sort-options="{ enabled: true }"
        :footer-props="{ showCount: true }"
        :pagination-options="{ enabled: true, perPage: 100 }"
    >
      <template v-slot:table-row="props">
        <span
            :style="props.column.field === 'votes' && props.row.votes === maxVotes ? highlightStyle : {}"
            v-if="props.column.field === 'party'"
        >
          <router-link :to="'/party/' + props.row.party">{{ props.row.party }}</router-link>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template v-slot:table-footer>
        <tr>
          <td>Total</td>
          <td>{{ totalVotes }}</td>
          <td>{{ totalPercentage }}%</td>
          <td>{{ totalConstituencies }}</td>
          <td>{{ totalWon }}</td>
        </tr>
      </template>
    </vue-good-table>
    <footer class="footer mt-4">
      <p>&copy; 2024 Election Results. All rights reserved.</p>
      <p class="disclaimer">
        Disclaimer: The data is sourced from the Election Commission of India. We are just providing an analytical platform to view the detailed information.
      </p>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import { VueGoodTable } from 'vue-good-table-next';

const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      totalVotes: 0,
      totalPercentage: 0,
      totalConstituencies: 0,
      totalWon: 0,
      results: {},
      columns: [
        { label: 'Party', field: 'party' },
        { label: 'Votes', field: 'votes', type: 'number', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          } },
        { label: 'Percentage of Votes', field: 'percentage', type: 'number', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          } },
        { label: 'Constituencies Contested', field: 'constituencies', type: 'number', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          } },
        { label: 'Constituencies Won', field: 'won', type: 'number', sortable: true }
      ],
      rows: [],
      maxVotes: 0,
      highlightStyle: {
        backgroundColor: '#ffd700' // Highlight color for the maximum number
      }
    };
  },
  mounted() {
    this.fetchResults();
  },
  methods: {
    async fetchResults() {
      try {
        const votesResponse = await axios.get(`${apiBaseUrl}/elections/votes-per-party`);
        const totalVotesResponse = await axios.get(`${apiBaseUrl}/elections/total-votes`);
        const constituenciesResponse = await axios.get(`${apiBaseUrl}/elections/constituencies-all`);

        this.results = votesResponse.data;
        this.totalVotes = totalVotesResponse.data;

        const partyConstituencies = {};
        const partyWins = {};

        constituenciesResponse.data.forEach(constituency => {
          let maxVotes = 0;
          let winningParty = '';

          constituency.candidates.forEach(candidate => {
            if (!partyConstituencies[candidate.party]) {
              partyConstituencies[candidate.party] = new Set();
            }
            partyConstituencies[candidate.party].add(constituency.constituencyId);

            const candidateVotes = candidate.votes + candidate.postalVotes;
            if (candidateVotes > maxVotes) {
              maxVotes = candidateVotes;
              winningParty = candidate.party;
            }
          });

          if (!partyWins[winningParty]) {
            partyWins[winningParty] = 0;
          }
          partyWins[winningParty]++;
        });

        this.prepareTableData(partyConstituencies, partyWins);
      } catch (error) {
        console.error('Error fetching election data:', error);
      }
    },
    prepareTableData(partyConstituencies, partyWins) {
      this.rows = Object.entries(this.results).map(([party, votes]) => {
        const percentage = ((votes / this.totalVotes) * 100).toFixed(2);
        const constituencies = partyConstituencies[party] ? partyConstituencies[party].size : 0;
        const won = partyWins[party] || 0;

        this.totalPercentage += parseFloat(percentage);
        this.totalConstituencies += constituencies;
        this.totalWon += won;

        return {
          party,
          votes,
          percentage,
          constituencies,
          won
        };
      });
      this.maxVotes = Math.max(...this.rows.map(row => row.votes));
      this.totalPercentage = this.totalPercentage.toFixed(2);
    }
  }
};
</script>



<style>
.table {
  margin-top: 10px;
}
.container {
  padding-top: 10px; /* Reduce top white space */
}
.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 20px;
}
.disclaimer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}
</style>
