import {
    getAllConstituencies,
    getConstituencyById,
    createConstituency,
    updateConstituency,
    deleteConstituency
} from '@/api';

const state = {
    constituencies: [],
    constituency: {}
};

const getters = {
    allConstituencies: (state) => state.constituencies,
    singleConstituency: (state) => state.constituency
};

const actions = {
    async fetchConstituencies({ commit }) {
        const response = await getAllConstituencies();
        commit('setConstituencies', response.data);
    },
    async fetchConstituency({ commit }, id) {
        const response = await getConstituencyById(id);
        commit('setConstituency', response.data);
    },
    async createConstituency({ commit }, data) {
        const response = await createConstituency(data);
        commit('newConstituency', response.data);
    },
    async updateConstituency({ commit }, { id, data }) {
        const response = await updateConstituency(id, data);
        commit('updateConstituency', response.data);
    },
    async deleteConstituency({ commit }, id) {
        await deleteConstituency(id);
        commit('removeConstituency', id);
    }
};

const mutations = {
    setConstituencies: (state, constituencies) => (state.constituencies = constituencies),
    setConstituency: (state, constituency) => (state.constituency = constituency),
    newConstituency: (state, constituency) => state.constituencies.push(constituency),
    updateConstituency: (state, updatedConstituency) => {
        const index = state.constituencies.findIndex(c => c.id === updatedConstituency.id);
        if (index !== -1) {
            state.constituencies.splice(index, 1, updatedConstituency);
        }
    },
    removeConstituency: (state, id) => (state.constituencies = state.constituencies.filter(c => c.id !== id))
};

export default {
    state,
    getters,
    actions,
    mutations
};
