<template>
  <section class="container">
    <h2>Constituencies</h2>
    <p class="description">
      This page provides a comprehensive overview of all constituencies. You can search, sort, and filter the data to view details such as the constituency name, description, total votes polled, EVM rounds, winning candidate, and winning party. Click on any constituency, candidate, or party name to navigate to their detailed pages.
    </p>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Constituencies</li>
      </ol>
    </nav>
    <vue-good-table
        :columns="columns"
        :rows="constituencies"
        :search-options="{ enabled: true }"
        :sort-options="{ enabled: true }"
        :pagination-options="{ enabled: true, perPage: 100 }"
    >
      <template v-slot:table-row="props">
        <span v-if="props.column.field === 'name'">
            <router-link :to="'/constituencies/' + props.row.constituencyId">{{ props.row.name }}</router-link>
        </span>
        <span v-else-if="props.column.field === 'winningCandidate'">
          <router-link :to="'/candidates/' + + props.row.constituencyId + '/candidate/' + props.row.winningCandidate">{{ props.row.winningCandidate }}</router-link>
        </span>
        <span v-else-if="props.column.field === 'winningParty'">
          <router-link :to="'/party/' + props.row.winningParty">{{ props.row.winningParty }}</router-link>
        </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <footer class="footer mt-4">
      <p>&copy; 2024 Election Results. All rights reserved.</p>
      <p class="disclaimer">
        Disclaimer: The data is sourced from the Election Commission of India. We are just providing an analytical platform to view the detailed information.
      </p>
    </footer>
  </section>
</template>

<script>
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';

const apiBaseUrl = process.env.VUE_APP_BACKEND_URL;
export default {
  components: {
    VueGoodTable
  },
  data() {
    return {
      constituencies: [],
      columns: [
        { label: 'S.N', field: 'constituencyId', sortable: true, sortMethod(a, b) {
            return a - b; // Numeric sort
          }, sortDirection: 'asc'},
        { label: 'Name', field: 'name', sortable: true, filterable: true },
        { label: 'Description', field: 'description', sortable: true },
        { label: 'Total Votes Polled', field: 'totalVotesPolled', sortable: true, type: 'number', sortMethod(a, b) {
            return a - b; // Numeric sort
          } },
        { label: 'EVM Rounds \'s', field: 'evmCount', sortable: true, type: 'number', sortMethod(a, b) {
            return a - b; // Numeric sort
          } },
        { label: 'Winning Candidate', field: 'winningCandidate', sortable: true },
        { label: 'Winning Party', field: 'winningParty', sortable: true }
      ],
      highlightStyle: {
        backgroundColor: '#ffd700' // Highlight color for the maximum number
      }
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(`${apiBaseUrl}/elections/constituencies`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.constituencies = data.map(constituency => {
          const totalVotesPolled = constituency.candidates.reduce((total, candidate) => {
            return total + candidate.votes + candidate.postalVotes;
          }, 0);

          const winningCandidate = constituency.candidates.reduce((prev, current) => {
            return (prev.votes + prev.postalVotes) > (current.votes + current.postalVotes) ? prev : current;
          });

          return {
            ...constituency,
            totalVotesPolled,
            winningCandidate: winningCandidate.name,
            winningCandidateId: winningCandidate.candidateId,
            winningParty: winningCandidate.party,
            winningPartyId: winningCandidate.partyId,
          };
        });
      } catch (error) {
        console.error('Error fetching constituencies:', error);
      }
    },
    getCellClass(field, value) {
      if (field === 'totalVotesPolled') {
        const maxVotes = Math.max(...this.constituencies.map(c => c.totalVotesPolled));
        return value === maxVotes ? 'highlight-cell' : '';
      }
      return '';
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

section {
  font-family: 'Roboto', sans-serif;
  text-align: left; /* Align content to the left */
}

h2 {
  color: #1f3c88; /* Darker blue for headers */
}

.breadcrumb {
  background-color: #f8f9fa;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
  color: #6c757d;
}

.breadcrumb-item a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.breadcrumb-item a:hover {
  color: #0056b3;
  text-decoration: underline;
}

p {
  font-size: 1.1em; /* Slightly larger text */
  margin: 10px 0;
}

a {
  color: #42b983;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, background-color 0.3s;
}

a:hover {
  color: #1f3c88;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px;
}

.table {
  margin-top: 20px;
}

.highlight-cell {
  background-color: #ffd700; /* Highlight color for the maximum number */
}

.description {
  font-size: 1.1em;
  margin-bottom: 1rem;
}
.container {
  padding-top: 10px; /* Reduce top white space */
}
.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  margin-top: 20px;
}
.disclaimer {
  margin-top: 10px;
  font-size: 0.9em;
  color: #6c757d;
}

</style>
