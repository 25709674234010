import {
    getAllGramPanchayats,
    getGramPanchayatById,
    createGramPanchayat,
    updateGramPanchayat,
    deleteGramPanchayat
} from '@/api';

const state = {
    gramPanchayats: [],
    gramPanchayat: {}
};

const getters = {
    allGramPanchayats: (state) => state.gramPanchayats,
    singleGramPanchayat: (state) => state.gramPanchayat
};

const actions = {
    async fetchGramPanchayats({ commit }) {
        const response = await getAllGramPanchayats();
        commit('setGramPanchayats', response.data);
    },
    async fetchGramPanchayat({ commit }, id) {
        const response = await getGramPanchayatById(id);
        commit('setGramPanchayat', response.data);
    },
    async createGramPanchayat({ commit }, data) {
        const response = await createGramPanchayat(data);
        commit('newGramPanchayat', response.data);
    },
    async updateGramPanchayat({ commit }, { id, data }) {
        const response = await updateGramPanchayat(id, data);
        commit('updateGramPanchayat', response.data);
    },
    async deleteGramPanchayat({ commit }, id) {
        await deleteGramPanchayat(id);
        commit('removeGramPanchayat', id);
    }
};

const mutations = {
    setGramPanchayats: (state, gramPanchayats) => (state.gramPanchayats = gramPanchayats),
    setGramPanchayat: (state, gramPanchayat) => (state.gramPanchayat = gramPanchayat),
    newGramPanchayat: (state, gramPanchayat) => state.gramPanchayats.push(gramPanchayat),
    updateGramPanchayat: (state, updatedGramPanchayat) => {
        const index = state.gramPanchayats.findIndex(gp => gp.id === updatedGramPanchayat.id);
        if (index !== -1) {
            state.gramPanchayats.splice(index, 1, updatedGramPanchayat);
        }
    },
    removeGramPanchayat: (state, id) => (state.gramPanchayats = state.gramPanchayats.filter(gp => gp.id !== id))
};

export default {
    state,
    getters,
    actions,
    mutations
};
