<template>
  <div id="app">
    <TotalResults />
  </div>
</template>

<script>
import TotalResults from '../components/TotalResults.vue';

export default {
  name: 'TotalResultsView',
  components: {
    TotalResults
  }
};
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.css';

@import 'vue-good-table-next/dist/vue-good-table-next.css';
</style>
