<template>
  <div class="container mt-4">
    <h2>{{ isEditMode ? 'Edit' : 'Add New' }} Gram Panchayat</h2>
    <form @submit.prevent="submitForm" class="row g-3">
      <div class="col-md-6">
        <label for="name" class="form-label">Name</label>
        <input type="text" id="name" v-model="gramPanchayat.name" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="description" class="form-label">Description</label>
        <input type="text" id="description" v-model="gramPanchayat.description" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="totalVoters" class="form-label">Total Voters</label>
        <input type="number" id="totalVoters" v-model="gramPanchayat.totalVoters" class="form-control" required />
      </div>
      <div class="col-md-6">
        <label for="evmCount" class="form-label">EVM Count</label>
        <input type="number" id="evmCount" v-model="gramPanchayat.evmCount" class="form-control" required />
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary me-2">{{ isEditMode ? 'Update' : 'Create' }}</button>
        <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'GramPanchayatForm',
  data() {
    return {
      gramPanchayat: {
        name: '',
        description: '',
        totalVoters: 0,
        evmCount: 0,
        // Initialize other fields as necessary
      },
      isEditMode: false
    };
  },
  created() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.fetchGramPanchayat(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions(['fetchGramPanchayat', 'createGramPanchayat', 'updateGramPanchayat']),
    fetchGramPanchayat(id) {
      this.$store.dispatch('fetchGramPanchayat', id).then(response => {
        this.gramPanchayat = response;
      });
    },
    cancel() {
      this.$router.push('/gram-panchayats');
    },
    submitForm() {
      if (this.isEditMode) {
        this.$store.dispatch('updateGramPanchayat', {
          id: this.$route.params.id,
          data: this.gramPanchayat
        }).then(() => {
          this.$router.push('/gram-panchayats');
        });
      } else {
        this.$store.dispatch('createGramPanchayat', this.gramPanchayat).then(() => {
          this.$router.push('/gram-panchayats');
        });
      }
    }
  }
};
</script>
